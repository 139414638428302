
import Address, { AddressName, AddressType } from '@/services/clients/Address'
import ClientList from '@/services/clients/ClientList'
import { Options, Vue, setup } from 'vue-class-component'

@Options({
  name: 'address-list-item',
  props: ['address', 'index', 'client'],
  emits: ['address-selected']
})
export default class AddressListItem extends Vue {
  address!: Address
  client!: ClientList
  index!: number

  context = setup(() => {
    function getFullAddress (address: Address): string {
      const noInt = address.AddressNoInt ? `INT: ${address.AddressNoInt}` : ''

      return `${address.AddressStreet} ${address.AddressNoExt} ${noInt} ${address.AddressNeighborhood} ${address.AddressZipCode}`
    }

    function getColor (address: Address): string {
      const colorMap = {
        [AddressName.Home]: 'primary',
        [AddressName.Work]: 'warning',
        [AddressName.Other]: 'dark',
        [AddressType.Recent]: 'success',
        Default: 'dark'
      }

      if (address.AddressTypeId === AddressType.Recent) {
        return colorMap[AddressType.Recent]
      }

      return colorMap[address.AddressName ?? 'Default'] ?? colorMap.Default
    }

    function getImage (address: Address): string {
      const iconMap = {
        [AddressName.Home]: '/media/icons/duotone/Home/Home.svg',
        [AddressName.Work]: '/media/icons/duotone/Interface/Briefcase.svg',
        [AddressName.Other]:
          '/media/icons/duotone/Communication/Adress-book2.svg',
        [AddressType.Recent]: '/media/icons/duotone/Home/Clock.svg',
        Default: '/media/icons/duotone/Communication/Adress-book2.svg'
      }

      if (address.AddressTypeId === AddressType.Recent) {
        return iconMap[AddressType.Recent]
      }

      return iconMap[address.AddressName ?? 'Default'] ?? iconMap.Default
    }

    const selectAddress = (address: Address): void => {
      this.$emit('address-selected', this.client, address)
    }

    return {
      getFullAddress,
      getColor,
      getImage,

      selectAddress
    }
  })
}
