
import Chat, { ChatType } from '@/services/chat/Chat'
import DateUtils from '@/utils/DateUtils'
import { reactive, toRefs } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { Options, prop, setup, Vue } from 'vue-class-component'

class ChatContactListProps {
  contacts = prop<Chat[]>({})
}

interface ChatContactListState {
  searchText: string,
  selectedChatType: ChatType
}

interface TypeCountMap {
  [type: string]: number
}

@Options({
  name: 'chat-contact-list',
  components: {},
  emits: ['contact-selected']
})
export default class ChatContactList extends Vue.with(ChatContactListProps) {
  context = setup(() => {
    const chatContactState = reactive<ChatContactListState>({
      searchText: '',
      selectedChatType: ChatType.Active
    })

    const filterType = (chat: Chat) => {
      return chat.ChatType === chatContactState.selectedChatType
    }

    const filterText = (chat: Chat) => {
      const textLower = chatContactState.searchText?.trim()?.toLowerCase()
      return chat.ChatName?.toLowerCase().includes(textLower) ||
        chat.ChatClientName?.toLowerCase().includes(textLower) ||
        chat.ChatClientNumber?.toLowerCase().includes(textLower)
    }

    const filteredChats = computed<Chat[]>(() => {
      return this.$props.contacts
        ?.filter(filterType)
        ?.filter(filterText) ?? []
    })

    const countPendingChats = (type: ChatType) : TypeCountMap => {
      return {
        [type]: this.$props.contacts
          ?.filter(contact => (contact.ChatMessageCount ?? 0) > 0 && contact.ChatType === type)
          ?.length ?? 0
      }
    }

    const chatCountMap = computed<TypeCountMap>(() => {
      return {
        ...countPendingChats(ChatType.Active),
        ...countPendingChats(ChatType.Waiting)
      }
    })

    const getSegmentType = (type: ChatType) : string => {
      return chatContactState.selectedChatType === type
        ? 'primary'
        : 'default'
    }

    const getBadgeType = (type: ChatType) : string => {
      return chatContactState.selectedChatType === type
        ? 'white'
        : 'primary'
    }

    const setSegmentType = (type: ChatType) => {
      chatContactState.selectedChatType = type
    }

    const onContactSelected = (contact: Chat) => {
      this.$emit('contact-selected', contact)
    }

    return {
      ...toRefs(chatContactState),
      chatContactState,
      ChatType,
      filteredChats,
      chatCountMap,

      getSegmentType,
      getBadgeType,
      setSegmentType,
      onContactSelected,

      ...DateUtils.toLiteral()
    }
  })
}
