
import { Product, ProductGroup } from '@/services/menu/MenuData'
import { Options, Vue, setup } from 'vue-class-component'

@Options({
  name: 'product-group-item',
  props: ['group', 'index'],
  emits: ['product-selected']
})
export default class MenuProductGroupItem extends Vue {
  group!: ProductGroup
  index!: number

  context = setup(() => {
    const onProductSelected = (product: Product) => {
      this.$emit('product-selected', product)
    }

    return {
      onProductSelected
    }
  })
}
