import authAxios from '@/services/core/AuthAxios'
import store from '@/store'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import ServiceHandler from '../core/ServiceHandler'
import AppWorkspace from '../workspaces/AppWorkspace'
import Address from '@/services/clients/Address'
import BranchData from './BranchData'
import { Services } from '@/store/enums/StoreEnums'
import { CartConfigInfo } from '@/store/modules/CartModule'
import BranchScheduleData from './BranchScheduleData'

import DateUtils from '@/utils/DateUtils'
import { Nullable } from '@/core/utils/CustomTypes'

class BranchService {
  getAllBranchList () {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.GET_BRANCH_LIST, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            OrderDate: null,
            GetClosedBranches: true,
            Location: null
          }
        })
        .then(ServiceHandler.checkSuccess<BranchData>(BranchData))
        .catch(ServiceHandler.catchError())
    })
  }

  getBranchListForLocation (address: Address) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.GET_BRANCH_LOCATION, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            OrderDate: null,
            GetClosedBranches: true,
            Location: {
              Latitude: address.AddressLat,
              Longitude: address.AddressLng
            }
          }
        })
        .then(ServiceHandler.checkSuccess<BranchData>(BranchData))
        .catch(ServiceHandler.catchError())
    })
  }

  getPickupBranchList () {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.GET_BRANCH_PICKUP, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            OrderDate: null,
            GetClosedBranches: true
          }
        })
        .then(ServiceHandler.checkSuccess<BranchData>(BranchData))
        .catch(ServiceHandler.catchError())
    })
  }

  getBranchSchedule (cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.GET_BRANCH_SCHEDULE, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            Date: DateUtils.getUtcNow().format(),
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp,
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            }
          }
        })
        .then(ServiceHandler.checkSuccess<BranchScheduleData>(BranchScheduleData))
        .catch(ServiceHandler.catchError())
    })
  }

  sendBranchInfo (chatId: Nullable<number>, branchIds: number[]) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.SEND_BRANCH_INFO, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ChatId: chatId,
            BranchList: branchIds.map(id => {
              return {
                BranchId: id
              }
            })
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }
}

export default new BranchService()
