import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row g-5 h-100 scroll" }
const _hoisted_2 = { class: "col-lg-12" }
const _hoisted_3 = { class: "card card-border card-stretch" }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "d-flex align-items-sm-start" }
const _hoisted_6 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_7 = { class: "d-flex flex-row-fluid align-items-center my-lg-0 me-2" }
const _hoisted_8 = { class: "flex-grow-1 my-lg-0 my-2 me-2" }
const _hoisted_9 = {
  href: "#",
  class: "text-gray-800 fw-bolder text-hover-primary fs-6"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "badge badge-light-warning" }
const _hoisted_12 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_13 = { class: "w-100 d-flex flex-column align-items-center" }
const _hoisted_14 = { class: "card-footer pb-0 pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_MenuGroupListItem = _resolveComponent("MenuGroupListItem")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _withDirectives(_createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, [
              _createVNode("div", {
                class: "symbol-label",
                style: `background-image: url(${_ctx.product?.ProductImage})`
              }, null, 4)
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, [
                _createVNode("a", _hoisted_9, _toDisplayString(_ctx.product?.ProductName), 1),
                (_ctx.product?.PromotionList?.length > 0)
                  ? (_openBlock(), _createBlock("div", _hoisted_10, [
                      _createVNode("span", _hoisted_11, _toDisplayString(_ctx.product?.PromotionList?.[0]?.PromotionName), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode("span", _hoisted_12, _toDisplayString(_ctx.product?.ProductDescription), 1)
              ])
            ])
          ])
        ], 512), [
          [_vShow, !_ctx.context.showItemModifierView]
        ]),
        _createVNode("div", {
          class: ["card-body", { 'pb-0': _ctx.context.showItemModifierView }]
        }, [
          _createVNode("div", _hoisted_13, [
            _withDirectives(_createVNode(_component_el_input_number, {
              modelValue: _ctx.context.productQuantity,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.context.productQuantity = $event)),
              class: "mx-5 mb-5",
              min: 1,
              max: 99
            }, null, 8, ["modelValue"]), [
              [_vShow, !_ctx.context.showItemModifierView]
            ])
          ]),
          _withDirectives(_createVNode("textarea", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.context.productComments = $event)),
            class: "form-control form-control-solid",
            id: "exampleFormControlTextarea1",
            style: {"resize":"none"},
            rows: "3",
            placeholder: "Comentarios y/o alergias"
          }, null, 512), [
            [_vShow, !_ctx.context.showItemModifierView],
            [_vModelText, _ctx.context.productComments]
          ]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.context.modifierList, (modifier, index) => {
            return _withDirectives((_openBlock(), _createBlock(_component_MenuGroupListItem, {
              class: {
              'mt-5': !_ctx.context.showItemModifierView,
              'h-100':
                _ctx.context.selectedModifierItemNode?.ModifierId ===
                modifier.ModifierId,
            },
              key: `${index}_${modifier.ModifierId}_${_ctx.product?.ProductId}_${_ctx.orderProduct?.OrderProductId}`,
              modifier: modifier,
              "selected-items": modifier.selectedItems,
              ref: 
              (el) => {
                modifier.modifierGroupRef = el
              }
            ,
              onItemModifierOpen: _ctx.context.onItemModifiersOpen,
              onItemModifierClosed: _ctx.context.onItemModifiersClosed
            }, null, 8, ["class", "modifier", "selected-items", "onItemModifierOpen", "onItemModifierClosed"])), [
              [_vShow, 
              !_ctx.context.selectedModifierItemNode ||
              _ctx.context.selectedModifierItemNode.ModifierId ===
                modifier.ModifierId
            ]
            ])
          }), 128))
        ], 2),
        _withDirectives(_createVNode("div", _hoisted_14, [
          _createVNode("a", {
            href: "#",
            class: "btn btn-light-primary w-100",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.context.onProductAdded()))
          }, _toDisplayString(_ctx.orderProduct ? 'Actualizar' : 'Agregar') + " $" + _toDisplayString(_ctx.context.productTotalValue.toFixed(2)), 1)
        ], 512), [
          [_vShow, !_ctx.context.showItemModifierView]
        ])
      ])
    ])
  ]))
}