import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card h-100" }
const _hoisted_2 = { class: "h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatContactList = _resolveComponent("ChatContactList")
  const _component_ChatWindow = _resolveComponent("ChatWindow")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("div", _hoisted_2, [
      _createVNode(_component_ChatContactList, {
        contacts: _ctx.context.chats,
        onContactSelected: _ctx.context.onContactSelected
      }, null, 8, ["contacts", "onContactSelected"])
    ], 512), [
      [_vShow, !_ctx.context.chatIsSelected]
    ]),
    _withDirectives(_createVNode(_component_ChatWindow, {
      contact: _ctx.context.selectedContact,
      clients: _ctx.context.clients,
      onBack: _ctx.context.onBackSelected,
      onSelectClient: _ctx.context.onSelectClient,
      onAddClient: _ctx.context.onAddClientSelected,
      onDeliverySelected: _ctx.context.onDeliverySelected,
      onPickupSelected: _ctx.context.onPickUpSelected
    }, null, 8, ["contact", "clients", "onBack", "onSelectClient", "onAddClient", "onDeliverySelected", "onPickupSelected"]), [
      [_vShow, _ctx.context.chatIsSelected]
    ])
  ]))
}