
import { reactive, toRefs, watch } from 'vue'
import { Options, Vue, setup } from 'vue-class-component'

import { CartConfigInfo } from '@/store/modules/CartModule'
import branchService from '@/services/branches/branch.service'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import BranchScheduleData, {
  BranchScheduleDay
} from '@/services/branches/BranchScheduleData'

import DateUtils from '@/utils/DateUtils'
import { Nullable } from '@/core/utils/CustomTypes'

interface ScheduleOrderStateInfo {
  branchScheduleDayList: BranchScheduleDay[]
  selectedBranchScheduleDayIndex: Nullable<number>
  selectedBranchIntervalIndex: Nullable<number>
}

@Options({
  name: 'schedule-order-dropdown',
  components: {},
  emits: ['schedule-selected', 'schedule-cancel']
})
export default class ScheduleOrderDropdown extends Vue {
  context = setup(() => {
    const scheduleOrderState = reactive<ScheduleOrderStateInfo>({
      branchScheduleDayList: [],
      selectedBranchScheduleDayIndex: null,
      selectedBranchIntervalIndex: null
    })

    const onDateSelected = () => {
      const targetIntervalList =
        scheduleOrderState.branchScheduleDayList?.[
          scheduleOrderState.selectedBranchScheduleDayIndex ?? -1
        ]

      const targetInterval =
        targetIntervalList?.BranchScheduleIntervalList?.[
          scheduleOrderState.selectedBranchIntervalIndex ?? -1
        ]

      if (targetInterval) {
        this.$emit('schedule-selected', targetInterval)
      }
    }

    const onCancel = () => {
      this.$emit('schedule-cancel')
    }

    const init = (cartConfig: CartConfigInfo) => {
      scheduleOrderState.selectedBranchScheduleDayIndex = null
      scheduleOrderState.branchScheduleDayList = []

      branchService
        .getBranchSchedule(cartConfig)
        .then((data: BranchScheduleData) => {
          scheduleOrderState.branchScheduleDayList = data.BranchScheduleDayList

          if (scheduleOrderState.branchScheduleDayList.length > 0) {
            scheduleOrderState.selectedBranchScheduleDayIndex = 0
          }
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    watch(
      () => scheduleOrderState.selectedBranchScheduleDayIndex,
      () => {
        scheduleOrderState.selectedBranchIntervalIndex = 0
      }
    )

    return {
      ...toRefs(scheduleOrderState),

      init,
      onDateSelected,
      onCancel,

      ...DateUtils.toLiteral()
    }
  })
}
