import { Nullable } from '@/core/utils/CustomTypes'
import SerializableObject from '../core/SerializableObject'

export default class MenuData extends SerializableObject {
  DivisionList: Division[] = []
}

export class MenuDivisionGroupData extends SerializableObject {
  DivisionGroupList: DivisionGroup[] = []
}

export class DivisionGroup extends SerializableObject {
  DivisionGroupId?: Nullable<number>
  DivisionGroupAliasId?: Nullable<string>
  DivisionGroupName?: Nullable<string>
  DivisionGroupDescription?: Nullable<string>
  DivisionGroupImage?: Nullable<string>
  DivisionGroupImageLarge?: Nullable<string>
  DivisionList: Division[] = []
}

export class Division extends SerializableObject {
  DivisionId?: number
  SourceId?: number
  DivisionExternalId?: string
  DivisionAliasId?: string
  DivisionIndex?: number
  DivisionUpdateDate?: string
  InStock?: boolean
  DivisionName?: string
  DivisionShortName?: string
  DivisionDescription?: string
  DivisionPublishedDate?: string
  DivisionUntilDate?: string
  DivisionDescriptionSchedule?: string
  DivisionIcon?: string
  DivisionImage?: string
  DivisionImageLarge?: string
  DivisionFromTime?: string
  DivisionUntilTime?: string
  DivisionMetaTitle?: string
  DivisionMetaDescription?: string
  DivisionKeywords?: string
  DivisionHeader?: string
  DivisionSubHeader?: string
  DivisionImageAlt?: string
  DivisionImageLargeAlt?: string
  DivisionProductGroupList?: ProductGroup[]
}

export class ProductGroup extends SerializableObject {
  GroupId?: number
  GroupName?: string
  GroupDescription?: string
  GroupType?: string
  GroupImage?: string
  GroupSuggestedImage?: string
  GroupProductList?: ProductGroupContent[]
}

export class ProductGroupContent extends SerializableObject {
  GroupProductAlias?: string
  Product?: Product
}

export interface Product {
  ProductId?: number
  ProductExternalId?: string
  ProductName?: string
  ProductImage?: string
  ProductImageAlt?: string
  ProductSuggestedImage?: string
  ProductDescription?: string
  ProductSuggestedDescription?: string
  ProductPrice?: number

  ProductAvailableInStore?: boolean
  ProductAvailableOnline?: boolean
  ProductDeliveryIsAllowed?: boolean
  ProductPickupIsAllowed?: boolean
  ProductIsExternalAvailable?: boolean
  ProductIsFavorite?: boolean
  ProductIsAvailable?: boolean
  ProductIsNotInTime?: boolean
  InStock?: boolean

  ModifierList?: Modifier[]
  ProductImageList?: ProductImage[]
  PromotionList?: ProductPromotion[]
}

export interface Modifier {
  ModifierId?: number
  SourceId?: number
  ModifierExternalId?: string
  ModifierIndex?: number
  ModifierName?: string
  ModifierMax?: number
  ModifierMin?: number
  ModifierIsMultipleQuantity: boolean
  ModifierIsActive: boolean

  ItemList?: ModifierItem[]
}

export interface ModifierItem {
  ItemId?: number
  ItemExternalId?: string
  SourceId?: number
  ItemIndex?: number

  ItemName?: string
  ItemQuantity?: number
  ItemPrice?: number
  ModifierId?: number
  ModifierExternalId?: string

  ItemMaxQuantity?: number
  ModifierList?: Modifier[]

  ItemIsActive: boolean
  ItemIsAvailable: boolean
  ItemIgnorePriceUpdate: boolean
}

export interface ProductImage {
  ProductId?: number
  ProductImageId?: number
  ProductImageUrl?: string
}

export interface ProductPromotion {
  PromotionId?: number
  PromotionName?: string
  PromotionTypeName?: string
  PromotionDescription?: string
  PromotionStartDate?: string
  PromotionEndDate?: string

  PromotionRequiredProducts?: number
  PromotionChargedProducts?: number
  PromotionFreeProducts?: number
  PromotionMaxPerOrder?: number
  PromotionIsAvailableForGuest?: boolean
}

export interface ProductPromotionType {
  PromotionTypeId: number
  PromotionTypeName: string
  PromotionQuantity: string
}
