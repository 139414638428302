import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "modal fade",
    id: _ctx.id,
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createVNode("div", {
      class: ["modal-dialog", `${_ctx.dialogClass}`]
    }, [
      _createVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ], 8, ["id"]))
}