import { Nullable } from '@/core/utils/CustomTypes'
import authAxios from '@/services/core/AuthAxios'
import store from '@/store'
import { Services } from '@/store/enums/StoreEnums'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import ServiceHandler from '../core/ServiceHandler'
import AppWorkspace from '../workspaces/AppWorkspace'
import ChatListResponseData from './ChatListResponseData'
import MessageListResponseData from './MessageListResponseData'
import MessageSendResponseData from './MessageSendResponseData'

class ChatService {
  getChatList () {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CHAT_LIST, workspace)

      return authAxios
        .post(serviceUrl, {})
        .then(ServiceHandler.checkSuccess<ChatListResponseData>(ChatListResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  getMessageList (chatId: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CHAT_MESSAGE_LIST, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ChatId: chatId
          }
        })
        .then(ServiceHandler.checkSuccess<MessageListResponseData>(MessageListResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  setChatRead (chatId: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CHAT_SET_READ, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ChatId: chatId
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }

  sendMessage (chatId: Nullable<number>, text: Nullable<string>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CHAT_MESSAGE_SEND, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ChatId: chatId,
            MessageBody: text
          }
        })
        .then(ServiceHandler.checkSuccess<MessageSendResponseData>(MessageSendResponseData))
        .catch(ServiceHandler.catchError())
    })
  }
}

export default new ChatService()
