
import Branch from '@/services/branches/Branch'
import branchService from '@/services/branches/branch.service'
import { computed, onMounted, reactive, toRefs, watch } from '@vue/runtime-core'
import { Options, Vue, setup } from 'vue-class-component'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import BranchData from '@/services/branches/BranchData'
import { Nullable } from '@/core/utils/CustomTypes'
import Chat from '@/services/chat/Chat'
import { useStore } from 'vuex'

interface BranchInfoListState {
  branches: Branch[]
  selectedBranches: number[],
  sendingRequest: boolean
}

@Options({
  name: 'branch-info-list',
  components: {}
})
export default class BranchInfoListComponent extends Vue {
  context = setup(() => {
    const branchInfoState = reactive<BranchInfoListState>({
      branches: [],
      selectedBranches: [],
      sendingRequest: false
    })

    const fetchBranhes = () => {
      branchService.getAllBranchList()
        .then((data: BranchData) => {
          branchInfoState.branches = data.BranchList ?? []
          branchInfoState.selectedBranches = []
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    onMounted(() => {
      fetchBranhes()
    })

    const store = useStore()
    const currentChat = computed<Nullable<Chat>>(
      () => store.getters.currentChat
    )

    watch(
      () => currentChat.value,
      (newValue) => {
        if (newValue) {
          fetchBranhes()
        }
      }
    )

    const sendBranchInfo = () => {
      if (!currentChat.value) {
        return
      }

      branchInfoState.sendingRequest = true
      branchService.sendBranchInfo(
        currentChat.value.ChatId,
        branchInfoState.selectedBranches)
        .then(() => {
          branchInfoState.sendingRequest = false
          branchInfoState.selectedBranches = []

          Swal.fire({
            text: 'Mensaje enviado con éxito',
            icon: 'success',
            confirmButtonText: 'Cerrar'
          })
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    return {
      ...toRefs(branchInfoState),

      sendBranchInfo
    }
  })
}
