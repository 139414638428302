
import { Actions } from '@/store/enums/StoreEnums'
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup, prop } from 'vue-class-component'
import { useStore } from 'vuex'

class FakeModalProps {
  id = prop<string>({})
  title = prop<string>({})
  showClose = prop<string>({})
  dialogClass = prop<string>({})
  bodyClass = prop<string>({})
}

@Options({
  name: 'fake-modal',
  components: {},
  emits: []
})
export default class FakeModal extends Vue.with(FakeModalProps) {
  context = setup(() => {
    const store = useStore()

    const hasFooter = computed<boolean>(() => !!this.$slots?.footer)

    const closeModal = () => {
      store.dispatch(Actions.HIDE_ALL_MODALS)
    }

    return {
      hasFooter,
      closeModal
    }
  })
}
