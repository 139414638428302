
import ChatContactList from '@/components/messages/ChatContactList.vue'
import ChatWindow from '@/components/messages/ChatWindow.vue'
import { CustomEvents, Nullable } from '@/core/utils/CustomTypes'
import { reactive, toRefs } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core'
import { Options, setup, Vue } from 'vue-class-component'
import Chat, { ChatType } from '@/services/chat/Chat'
import chatService from '@/services/chat/chat.service'
import ChatListResponseData from '@/services/chat/ChatListResponseData'
import MessageListResponseData from '@/services/chat/MessageListResponseData'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import ClientList from '@/services/clients/ClientList'
import clientService from '@/services/clients/client.service'
import ClientSearchData from '@/services/clients/ClientSearchData'
import { ServiceType } from '@/store/modules/ServiceTypeModule'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { Emitter } from 'mitt'
import { inject } from 'vue'
import { debounce } from 'debounce'
import Message from '@/services/chat/Message'

interface ClientChatState {
  chats: Chat[],
  selectedContact: Nullable<Chat>,
  clients: ClientList[],
  sound: Nullable<HTMLAudioElement>
}

@Options({
  name: 'client-chat',
  components: {
    ChatContactList,
    ChatWindow
  },
  emits: ['delivery-selected', 'pickup-selected', 'add-client', 'select-client']
})
export default class ClientChatComponent extends Vue {
  context = setup(() => {
    const emitter = inject<Emitter<CustomEvents>>('emitter')
    const store = useStore()

    const ignoreChatType = [ChatType.Other, ChatType.Bot]
    const clientChatState = reactive<ClientChatState>({
      chats: [],
      selectedContact: null,
      clients: [],
      sound: null
    })

    onMounted(() => {
      clientChatState.sound = new Audio('/media/sound/note.mp3')
      clientChatState.sound.loop = false
    })

    const playNotification = debounce(() => {
      if (!clientChatState.sound) {
        return
      }

      clientChatState.sound.pause()
      clientChatState.sound.currentTime = 0
      clientChatState.sound.play()
    }, 100, true)

    const chatPendingCount = computed<number>(() => {
      const chatPendingCount = clientChatState.chats
        .filter(c => (c.ChatMessageCount ?? 0) > 0 && !ignoreChatType.includes(c.ChatType))
        .length

      return chatPendingCount
    })

    watch(() => chatPendingCount.value, () => {
      store.dispatch(Actions.SET_CHAT_COUNT, chatPendingCount.value)
    })

    const fillChatType = (chats: Nullable<Chat[]>, type: ChatType) => {
      return chats?.map((chat) => {
        chat.ChatType = type
        return chat
      }) ?? []
    }

    const fetchChats = () => {
      chatService
        .getChatList()
        .then((data: ChatListResponseData) => {
          const activeChats = fillChatType(data.ChatActiveList, ChatType.Active)
          const waitingChats = fillChatType(data.ChatWaitingList, ChatType.Waiting)
          const otherChats = fillChatType(data.ChatOtherList, ChatType.Other)
          const botChats = fillChatType(data.ChatAutoList, ChatType.Bot)

          var chats = [...activeChats, ...waitingChats, ...otherChats, ...botChats]
          const newChats = chats.map((newChat) => {
            newChat.MessageList = clientChatState
              .chats
              .filter((oldChat) => oldChat.ChatId === newChat.ChatId)[0]
              ?.MessageList

            return newChat
          })

          const oldChatsNoRed = clientChatState.chats
            .filter(c => (c.ChatMessageCount ?? 0) > 0)

          const newPendingChats = newChats
            .filter(chat => {
              const oldChat = oldChatsNoRed.filter(c => c.ChatId === chat.ChatId)?.[0]
              return !ignoreChatType.includes(chat.ChatType) && (chat.ChatMessageCount ?? 0) > (oldChat?.ChatMessageCount ?? 0)
            })

          if (newPendingChats.length > 0) {
            playNotification()
          }

          if (clientChatState.selectedContact) {
            newChats
              .filter(c => c.ChatId === clientChatState.selectedContact?.ChatId)
              .forEach(c => {
                c.ChatMessageCount = 0
              })
          }

          clientChatState.chats = newChats
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const getNewMessages = (chat: Chat, messages: Message[]) : Message[] => {
      const currentMessageIdList = chat
        ?.MessageList
        ?.map(chat => chat.MessageId) ?? []

      return messages
        .filter(m => !currentMessageIdList.includes(m.MessageId))
        .reverse()
    }

    const fetchMessages = (contact: Chat) => {
      chatService
        .getMessageList(contact?.ChatId)
        .then((data: MessageListResponseData) => {
          const newMessages = getNewMessages(contact, data.MessageList)
          contact.MessageList = [...(contact.MessageList ?? []), ...newMessages]

          setChatRead(contact)
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const setChatRead = (contact: Chat) => {
      contact.ChatMessageCount = 0
      chatService
        .setChatRead(contact.ChatId)
        .catch(() => {
          // ignored
        })
    }

    const fetchClients = (phone: string) => {
      const trimedPhone = phone.substring(phone.length - 10)
      clientService.searchClientList(trimedPhone)
        .then((data: ClientSearchData) => {
          clientChatState.clients = data.ClientList ?? []
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    onMounted(() => {
      fetchChats()
    })

    const chatIsSelected = computed<boolean>(() => {
      return !!clientChatState.selectedContact
    })

    const update = () => {
      if (clientChatState.selectedContact) {
        fetchMessages(clientChatState.selectedContact)
        fetchClients(clientChatState.selectedContact?.ChatClientNumber ?? '')
      }
    }

    const onContactSelected = (contact: Chat) => {
      clientChatState.selectedContact = contact
      fetchMessages(contact)
      fetchClients(contact?.ChatClientNumber ?? '')

      store.dispatch(Actions.CART_CLEAR_ALL)
      emitter?.emit('clearOrder', '')

      store.dispatch(Actions.HIDE_ALL_MODALS)
      store.dispatch(Actions.SET_CURRENT_CHAT, contact)
    }

    const onBackSelected = () => {
      clientChatState.selectedContact = null
      clientChatState.clients = []
      fetchChats()

      store.dispatch(Actions.CART_CLEAR_ALL)
      emitter?.emit('clearOrder', '')

      store.dispatch(Actions.HIDE_ALL_MODALS)
      store.dispatch(Actions.CLEAR_CURRENT_CHAT)
    }

    const onSelectClient = (chat: Chat, clients: ClientList[]) => {
      this.$emit('select-client', chat, clients)
    }

    const onAddClientSelected = (phone: string) => {
      this.$emit('add-client', phone)
    }

    const onDeliverySelected = (client: ClientList) => {
      this.$emit('delivery-selected', client)
    }

    const onPickUpSelected = (client: ClientList) => {
      this.$emit('pickup-selected', client)
    }

    const updateChat = () => {
      if (clientChatState.selectedContact) {
        fetchMessages(clientChatState.selectedContact)
        // fetchClients(clientChatState.selectedContact?.ChatClientNumber ?? '')
      }

      fetchChats()
    }

    setInterval(() => {
      updateChat()
    }, 30 * 1000)

    emitter?.on('chatUpdate', updateChat)

    const currentServiceType = computed<ServiceType>(
      () => store.getters.currentServiceType
    )

    watch<ServiceType>(() => currentServiceType.value, (value) => {
      if (value !== ServiceType.Chat) {
        clientChatState.selectedContact = null
        clientChatState.clients = []
      }
    })

    return {
      ...toRefs(clientChatState),
      chatIsSelected,
      update,

      onSelectClient,
      onContactSelected,
      onBackSelected,
      onAddClientSelected,
      onDeliverySelected,
      onPickUpSelected
    }
  })
}
