import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "symbol symbol-50px me-5" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = {
  href: "#",
  class: "text-dark text-hover-primary fs-6 fw-bolder"
}
const _hoisted_4 = { class: "text-muted fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", {
    class: [{ 'mb-0': _ctx.address.length - 1 !== _ctx.index }, "d-flex align-items-center bg-hover-light py-3 px-3"],
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.context.selectAddress(_ctx.address)))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("span", {
        class: [`bg-light-${_ctx.context.getColor(_ctx.address)}`, "symbol-label"]
      }, [
        _createVNode("span", {
          class: [`svg-icon-${_ctx.context.getColor(_ctx.address)}`, "svg-icon svg-icon-2x"]
        }, [
          _createVNode(_component_inline_svg, {
            src: _ctx.context.getImage(_ctx.address)
          }, null, 8, ["src"])
        ], 2)
      ], 2)
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("a", _hoisted_3, _toDisplayString(`${_ctx.address.AddressStreet} ${_ctx.address.AddressNoExt}`), 1),
      _createVNode("span", _hoisted_4, _toDisplayString(_ctx.context.getFullAddress(_ctx.address)), 1)
    ])
  ], 2))
}