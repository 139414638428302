import { Nullable } from "@/core/utils/CustomTypes";
import SerializableObject from "../core/SerializableObject";

export default class BranchScheduleData extends SerializableObject {
    BranchScheduleAsapCost: Nullable<BranchScheduleAsapCost> = null
    BranchScheduleDayList: BranchScheduleDay[] = []
}

export interface BranchScheduleAsapCost {
    BranchChargeFee?: Nullable<number>
    BranchChargeFeePercentage?: Nullable<number>
}

export interface BranchScheduleDay {
    Date?: Nullable<string>
    BranchScheduleIntervalList?: BranchScheduleInterval[]
}

export interface BranchScheduleInterval {
    BranchScheduleIntervalStart?: Nullable<string>
    BranchScheduleIntervalEnd?: Nullable<string>
    BranchScheduleChargeFee?: Nullable<number>
    BranchScheduleChargeFeePercentage?: Nullable<number>
}