
import ClientList from '@/services/clients/ClientList'
import { Options, prop, setup, Vue } from 'vue-class-component'
import { reactive, toRefs } from 'vue'

import Address from '@/services/clients/Address'
import ClientSearchList from '@/components/client/ClientSearchList.vue'

class ClientAccordionListProps {
  clientList = prop<ClientList[]>({})
}

interface ClientListState {
  searchString: string
  searchExecuted: boolean
  searchExecuting: boolean
}

@Options({
  name: 'client-search-component',
  components: {
    ClientSearchList
  },
  emits: ['address-selected', 'pickup-selected', 'new-address', 'add-client']
})
export default class ClientAccordionListComponent extends Vue.with(ClientAccordionListProps) {
  context = setup(() => {
    const searchSate = reactive<ClientListState>({
      searchString: '',
      searchExecuted: false,
      searchExecuting: false
    })

    const onSelectAddClient = () => {
      var clientList = this.$props.clientList ?? []
      const phone = clientList.length > 0 ? '' : searchSate.searchString
      this.$emit('add-client', phone)
    }

    const onAddressSelect = (client: ClientList, address: Address) => {
      this.$emit('address-selected', client, address)
    }

    const onPickupSelected = (client: ClientList) => {
      this.$emit('pickup-selected', client)
    }

    const onNewAddressSelected = (client: ClientList) => {
      this.$emit('new-address', client)
    }

    return {
      ...toRefs(searchSate),

      onSelectAddClient,
      onAddressSelect,
      onPickupSelected,
      onNewAddressSelected
    }
  })
}
