import { Nullable } from '@/core/utils/CustomTypes'
import SerializableObject from '../core/SerializableObject'
import Address from './Address'

export default class ClientList extends SerializableObject {
  UserId?: Nullable<number>
  UserFirstName?: Nullable<string>
  UserLastName?: Nullable<string>
  UserFullName?: Nullable<string>
  ClientPhone?: Nullable<string>
  ClientImgUrl?: Nullable<string>
  ClientEmail?: Nullable<string>
  ClientIsGuest?: Nullable<boolean>
  ClientIsCallcenter?: Nullable<boolean>
  AddressList?: Address[]

  constructor (id?: number, firstName?: string, lastName?: string, fullName?: string, phone?: string, email?: string, isGuest = false, addressList?: Address[]) {
    super()

    this.UserId = id ?? null
    this.UserFirstName = firstName ?? null
    this.UserLastName = lastName ?? null
    this.UserFullName = fullName ?? null

    this.ClientPhone = phone ?? null
    this.ClientEmail = email ?? null
    this.ClientIsGuest = isGuest

    this.AddressList = addressList ?? []
  }
}
