
import { computed, onMounted, reactive, toRefs, watch } from 'vue'
import { Options, prop, setup, Vue } from 'vue-class-component'
import { debounce } from 'debounce'

import orderService from '@/services/order/order.service'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import OrderHistoryResponseData from '@/services/order/responses/OrderHistoryResponseData'
import Order from '@/services/order/Order'
import DateUtils from '@/utils/DateUtils'

import OrderDetailDrawer from '@/components/orders/drawer/OrderDetailDrawer.vue'
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent'
import { MenuComponent, ScrollComponent, ScrollTopComponent, StickyComponent, ToggleComponent } from '@/assets/ts/components'
import { Nullable } from '@/core/utils/CustomTypes'
import store from '@/store'
import { useRoute } from 'vue-router'
import Chat from '@/services/chat/Chat'

class HistoryOrdersProps {
  showSendControls = prop<boolean>({ default: false })
}

interface DatePickerShortcut {
  text: string,
  value: Date[]
}

interface HistoryOrderStateInfo {
  searchString: string,
  pageSize: number,
  currentPage: number,
  orderCount: number,
  filterDate: Date[],
  orderList: Order[],
  selectedOrder: Nullable<Order>,
  shortcuts: DatePickerShortcut[],

  selectedOrders: number[],
  sendingRequest: boolean
}

@Options({
  name: 'history-orders',
  components: {
    OrderDetailDrawer
  }
})
export default class HistoryOrdersComponent extends Vue.with(HistoryOrdersProps) {
  context = setup(() => {
    const route = useRoute()

    const currentChat = computed<Nullable<Chat>>(
      () => route.name === 'new-order'
        ? store.getters.currentChat as Chat
        : null
    )

    const historyOrdersState = reactive<HistoryOrderStateInfo>({
      searchString: '',
      pageSize: 10,
      currentPage: 1,
      orderCount: 0,
      selectedOrders: [],
      sendingRequest: false,
      filterDate: (() => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        return [start, end]
      })(),
      orderList: [],
      selectedOrder: null,
      shortcuts: [{
        text: 'Última semana',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }, {
        text: 'Último mes',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })()
      }, {
        text: 'Últimos 3 meses',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })()
      }]
    })

    const filteredOrderList = computed(() => {
      const text = historyOrdersState.searchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      return historyOrdersState.orderList
        .filter((order) => {
          return !historyOrdersState.searchString ||
            order.OrderId?.toString()?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.OrderCheffId?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.ClientName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.BranchName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.OrderStatus?.OrderStatusName?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.OrderStatus?.OrderStatusShortName?.toLowerCase()?.includes(text.toLowerCase())
        })
    })

    const loadActiveOrders = () => {
      const startDate = historyOrdersState.filterDate[0]?.toISOString()
      const endDate = historyOrdersState.filterDate[1]?.toISOString()
      orderService.getHistoryOrderList(
        startDate,
        endDate,
        undefined,
        historyOrdersState.searchString,
        currentChat.value?.ChatClientNumber,
        historyOrdersState.pageSize,
        historyOrdersState.currentPage)
        .then((data: OrderHistoryResponseData) => {
          historyOrdersState.orderList = data.OrderList ?? []
          historyOrdersState.orderCount = data.PageInfo?.Total ?? data.OrderList.length
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    onMounted(() => {
      loadActiveOrders()

      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      ScrollTopComponent.reinitialization()
      DrawerComponent.reinitialization()
      StickyComponent.reInitialization()
      MenuComponent.reinitialization()
    })

    const onSearchInput = debounce(loadActiveOrders, 500)

    const onPageChange = (page: number) => {
      historyOrdersState.currentPage = page
      loadActiveOrders()
    }

    const onOrderClick = (order: Order) => {
      historyOrdersState.selectedOrder = order
      const drawerElement = document.getElementById('kt_activities')
      if (!drawerElement) {
        return
      }

      const drawerInstance = DrawerComponent.getInstance(drawerElement)
      drawerInstance?.show?.()

      const scrollElement: HTMLElement | null = drawerElement.querySelector('[data-kt-scroll="true"]')
      if (!scrollElement) {
        return
      }

      const scrollInstance = ScrollComponent.getInstance(scrollElement)
      scrollInstance?.update?.()
    }

    watch(
      () => currentChat.value,
      onSearchInput
    )

    const sendOrderInfo = () => {
      if (!currentChat.value || !this.$props.showSendControls) {
        return
      }

      historyOrdersState.sendingRequest = true
      orderService.sendOrderInfo(
        currentChat.value.ChatId,
        historyOrdersState.selectedOrders)
        .then(() => {
          historyOrdersState.sendingRequest = false
          historyOrdersState.selectedOrders = []

          Swal.fire({
            text: 'Mensaje enviado con éxito',
            icon: 'success',
            confirmButtonText: 'Cerrar'
          })
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    return {
      ...toRefs(historyOrdersState),
      filteredOrderList,
      loadActiveOrders,
      onSearchInput,
      onPageChange,
      onOrderClick,
      sendOrderInfo,

      ...DateUtils.toLiteral()
    }
  })
}
