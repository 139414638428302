
import { reactive, toRefs } from 'vue'
import { Options, Vue, setup, prop } from 'vue-class-component'
import { ErrorMessage, Field, Form, PublicFormContext } from 'vee-validate'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as Yup from 'yup'
import Address, { AddressName, AddressType } from '@/services/clients/Address'

import ClientList from '@/services/clients/ClientList'
import ClientService from '@/services/clients/client.service'
import AddressData from '@/services/clients/AddressData'
import { Nullable } from '@/core/utils/CustomTypes'

import AddressForm from '@/components/address/AddressForm.vue'
import ClientAddData from '@/services/clients/ClientAddData'

class ClientNewFormProps {
  hideHeader = prop<boolean>({})
}

interface AddressNameItem {
  id: string
  name: string
  icon: string
  color: string
  isHovering: boolean
}

interface AddressModalState {
  client: ClientList,
  newAddress: Address,
  addressFormRef: Nullable<AddressForm>,
  addressNameList: AddressNameItem[]
  submitButtonRef: Nullable<HTMLButtonElement>
  addressAutocompleteRef: Nullable<HTMLInputElement>
  mapDivRef: Nullable<HTMLElement>
  newAddressFormRef: Nullable<PublicFormContext>
  markerIsDragging: boolean
  addAddress: boolean
}

@Options({
  name: 'client-new-form',
  components: {
    ErrorMessage,
    Field,
    Form,
    AddressForm
  },
  emits: ['client-added', 'cancel']
})
export default class ClientNewFormComponent extends Vue.with(ClientNewFormProps) {
  defaultClient = new ClientList().from({
    UserFirstName: '',
    UserLastName: '',
    ClientPhone: ''
  })

  defaultAddress = new Address().from({
    AddressId: null,
    AddressTypeId: AddressType.Favorite,
    AddressLat: null,
    AddressLng: null,
    AddressName: AddressName.Other,
    AddressStreet: '',
    AddressNoExt: '',
    AddressNoInt: '',
    AddressNeighborhood: '',
    AddressMunicipality: '',
    AddressState: '',
    AddressZipCode: '',
    AddressReference: '',
    AddressPhone: '',
    AddressExtension: '',
    AddressGoogleDesc: null,
    AddressGoogleId: null
  })

  google: any = null
  map: any = null
  marker: any = null

  context = setup(() => {
    const addressModalState = reactive<AddressModalState>({
      client: new ClientList().from(this.defaultClient),
      newAddress: new Address().from(this.defaultAddress),
      addressFormRef: null,
      addressNameList: [
        {
          id: AddressName.Home,
          name: 'Casa',
          icon: '/media/icons/duotone/Home/Home.svg',
          color: 'primary',
          isHovering: false
        },
        {
          id: AddressName.Work,
          name: 'Trabajo',
          icon: '/media/icons/duotone/Interface/Briefcase.svg',
          color: 'warning',
          isHovering: false
        },
        {
          id: AddressName.Other,
          name: 'Otra',
          icon: '/media/icons/duotone/Communication/Adress-book2.svg',
          color: 'dark',
          isHovering: false
        }
      ],
      newAddressFormRef: null,
      submitButtonRef: null,
      mapDivRef: null,
      addressAutocompleteRef: null,
      markerIsDragging: false,
      addAddress: true
    })

    const dispose = () => {
      addressModalState.client = new ClientList().from(this.defaultClient)
      addressModalState.newAddress = new Address().from(this.defaultAddress)
      addressModalState.newAddressFormRef?.resetForm()

      addressModalState.addAddress = true
    }

    const init = (phone: string) => {
      dispose()

      addressModalState.addressFormRef?.init()
      addressModalState.addAddress = true

      if (/^\d+$/.test(phone.trim())) {
        addressModalState.client.ClientPhone = phone.trim()
      }
    }

    const requiredOnTrue = () => {
      return {
        is: true,
        then: (schema) => schema.required()
      }
    }

    const validationSchema = Yup.object().shape({
      UserFirstName: Yup.string()
        .required()
        .label('Nombre'),
      UserLastName: Yup.string()
        .required()
        .label('Apellidos'),
      ClientPhone: Yup.string()
        .required()
        .max(10)
        .min(10)
        .matches(/[0-9]/, 'Sólo dígitos')
        .label('Teléfono'),
      AddressStreet: Yup.string()
        .when('addAddress', requiredOnTrue())
        .label('Calle'),
      AddressNoExt: Yup.string()
        .when('addAddress', requiredOnTrue())
        .label('No. Exterior'),
      AddressNoInt: Yup.string()
        .label('No. Interior'),
      AddressZipCode: Yup.string()
        .max(5)
        .when('addAddress', requiredOnTrue())
        .when('addAddress', {
          is: true,
          then: (schema) => schema.matches(/^([0-9]+)?$/, 'Sólo dígitos')
        })
        .label('C.P'),
      AddressNeighborhood: Yup.string()
        .when('addAddress', requiredOnTrue())
        .label('Colonia'),
      AddressMunicipality: Yup.string()
        .when('addAddress', requiredOnTrue())
        .label('Ciudad'),
      AddressState: Yup.string()
        .when('addAddress', requiredOnTrue())
        .label('Estado')
    })

    const submit = () => {
      if (!addressModalState.submitButtonRef) {
        return
      }

      // Disable button
      addressModalState.submitButtonRef.disabled = true
      // Activate indicator
      addressModalState.submitButtonRef.setAttribute('data-kt-indicator', 'on')

      if (addressModalState.submitButtonRef) {
        addressModalState.submitButtonRef.disabled = false
        addressModalState.submitButtonRef?.removeAttribute('data-kt-indicator')
      }

      addressModalState.newAddress.AddressName =
        addressModalState.newAddress.AddressName ?? AddressName.Other

      ClientService
        .addClient(addressModalState.client)
        .then((clientData: ClientAddData) => {
          if (clientData.Client) {
            addressModalState.client = clientData.Client
          }

          if (!addressModalState.addAddress) {
            return null
          }

          return ClientService
            .addAddress(addressModalState.newAddress, addressModalState.client.UserId)
        })
        .then((addressData: Nullable<AddressData>) => {
          const address = addressData?.Address
          addressModalState.newAddress.AddressId = address?.AddressId ?? null

          this.$emit('client-added', addressModalState.client, addressModalState.newAddress)
          /* Swal.fire({
            text: 'Form has been successfully submitted!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          }).then(() => {
            this.$emit('client-added', addressModalState.client, addressModalState.newAddress)
          }) */
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })

          addressModalState.submitButtonRef?.removeAttribute('data-kt-indicator')
        })
    }

    const onCancel = () => {
      this.$emit('cancel')
    }

    return {
      ...toRefs(addressModalState),

      validationSchema,
      submit,
      onCancel,

      init,
      dispose
    }
  })
}
