
import { reactive, toRefs } from '@vue/reactivity'
import { Options, Vue, setup } from 'vue-class-component'

import { Modal } from 'bootstrap'
import { onMounted } from '@vue/runtime-core'
import { Nullable } from '@/core/utils/CustomTypes'
import CartPlaceInput from '@/services/cart/CartPlaceInput'
import cartService from '@/services/cart/cart.service'
import { useStore } from 'vuex'
import { CartConfigInfo } from '@/store/modules/CartModule'

import { RadarSpinner } from 'epic-spinners'
import { computed } from 'vue'
import Chat from '@/services/chat/Chat'

interface CartPlaceModalStateInfo {
  orderPlace: Nullable<CartPlaceInput>
  modal: Nullable<Modal>
  showCancelButton: boolean
  timer: Nullable<any>
}

@Options({
  name: 'cart-place-modal',
  components: {
    RadarSpinner
  },
  emits: ['success', 'error', 'cancel']
})
export default class CartPlaceModal extends Vue {
  context = setup(() => {
    const store = useStore()

    const currentChat = computed<Chat>(
      () => store.getters.currentChat
    )

    const cartPlaceModalState = reactive<CartPlaceModalStateInfo>({
      orderPlace: null,
      modal: null,
      showCancelButton: true,
      timer: null
    })

    const hide = () => {
      cartPlaceModalState.timer && clearTimeout(cartPlaceModalState.timer)
      cartPlaceModalState.modal?.hide()

      cartPlaceModalState.orderPlace = null
      cartPlaceModalState.showCancelButton = true
    }

    const show = (orderPlace: CartPlaceInput) => {
      cartPlaceModalState.orderPlace = orderPlace
      cartPlaceModalState.showCancelButton = true
      cartPlaceModalState.modal?.show()

      cartPlaceModalState.timer && clearTimeout(cartPlaceModalState.timer)
      cartPlaceModalState.timer = setTimeout(() => {
        cartPlaceModalState.showCancelButton = false

        const cartInfo = store.getters
          .currentConfig as Nullable<CartConfigInfo>

        if (!cartInfo || !orderPlace) {
          return
        }

        const placeFunction = cartInfo.Client?.ClientIsCallcenter
          ? cartService.placeGuest
          : cartService.place

        placeFunction(orderPlace, cartInfo, currentChat.value?.ChatId)
          .then(() => {
            hide()
            this.$emit('success')
          })
          .catch((error: Error) => {
            hide()
            this.$emit('error', error)
          })
      }, 7 * 1000)
    }

    const cancel = () => {
      hide()
      this.$emit('cancel')
    }

    onMounted(() => {
      const modalElement = document.getElementById('kt_modal_cart_place')
      const config = {
        backdrop: 'static',
        keyboard: false
      }

      cartPlaceModalState.modal = new Modal(modalElement, config)
    })

    return {
      ...toRefs(cartPlaceModalState),

      show,
      hide,
      cancel
    }
  })

  show (orderPlace: CartPlaceInput) : void {
    this.context?.show(orderPlace)
  }

  hide () : void {
    this.context?.hide()
  }
}
