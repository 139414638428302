
import Address from '@/services/clients/Address'
import ClientList from '@/services/clients/ClientList'
import AddressListItem from '@/components/address/AddressListItem.vue'
import { Options, Vue, setup, prop } from 'vue-class-component'

class ClientSearchListProps {
  accordionId = prop<string>({})
  client = prop<ClientList>({})
  index = prop<number>({})
  length = prop<number>({})
}

@Options({
  name: 'client-search-list',
  emits: ['address-selected', 'pickup-selected', 'new-address'],
  components: {
    AddressListItem
  }
})
export default class ClientSearchListComponent extends Vue.with(
  ClientSearchListProps
) {
  context = setup(() => {
    const selectAddress = (client: ClientList, address: Address): void => {
      this.$emit('address-selected', this.$props.client, address)
    }

    const selectPickup = (): void => {
      this.$emit('pickup-selected', this.$props.client)
    }

    const newAddress = (): void => {
      this.$emit('new-address', this.$props.client)
    }

    return {
      selectAddress,
      selectPickup,
      newAddress
    }
  })
}
