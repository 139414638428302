
import { reactive, toRefs, onMounted, computed, watch, inject, onBeforeUnmount } from 'vue'
import { Vue, Options, setup } from 'vue-class-component'
import { useStore } from 'vuex'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'

import ClientList from '@/services/clients/ClientList'

import BranchService from '@/services/branches/branch.service'
import BranchData from '@/services/branches/BranchData'

import menuService from '@/services/menu/menu.service'

import Address from '@/services/clients/Address'
import MenuAccordionList from '@/components/menu/MenuAccordionList.vue'
import ProductDetail from '@/components/menu/ProductDetail.vue'

import GenericModal from '@/components/modals/general/GenericModal.vue'
import FormModal from '@/components/modals/general/FormModal.vue'
import FakeModal from '@/components/modals/general/FakeModal.vue'
import FakeFormModal from '@/components/modals/general/FakeFormModal.vue'
import SelectAddressComponent from '@/components/address/AddressSelect.vue'
import SelectPickupBranchComponent from '@/components/branch/SelectPickupBranch.vue'
import SelectBranchComponent from '@/components/branch/SelectBranch.vue'
import NoCoverageBranchComponent from '@/components/branch/NoCoverageBranch.vue'
import AddressFormWindowComponent from '@/components/address/AddressFormWindow.vue'
import ClientNewFormComponent from '@/components/client/ClientNewForm.vue'

import BranchInfoListComponent from '@/components/branch/BranchInfoList.vue'
import HistoryOrdersComponent from '@/views/orders/HistoryOrders.vue'

import CartDetail from '@/components/cart/CartDetail.vue'

import { Modal } from 'bootstrap'
import Branch from '@/services/branches/Branch'
import { Actions } from '@/store/enums/StoreEnums'

import ScheduleOrderDropdown from '@/components/cart/dropdown/ScheduleOrderDropdown.vue'
import { MenuComponent } from '@/assets/ts/components'
import { BranchScheduleInterval } from '@/services/branches/BranchScheduleData'
import { CartConfigInfo } from '@/store/modules/CartModule'
import MenuData, { Division, DivisionGroup, MenuDivisionGroupData, Product } from '@/services/menu/MenuData'
import Cart from '@/services/cart/Cart'
import { CustomEvents, Nullable } from '@/core/utils/CustomTypes'
import { Emitter } from 'mitt'
import MenuDivisionGroupList from '@/components/menu/MenuDivisionGroupList.vue'
import AppWorkspace from '@/services/workspaces/AppWorkspace'
import ClientSearchComponent from '@/components/client/ClientSearch.vue'
import ClientAccordionListComponent from '@/components/client/ClientAccordionList.vue'
import ClientChatComponent from '@/components/messages/ClientChat.vue'
import { ServiceType } from '@/store/modules/ServiceTypeModule'
import Chat from '@/services/chat/Chat'
import chatService from '@/services/chat/chat.service'
import MessageSendResponseData from '@/services/chat/MessageSendResponseData'

import dedent from 'dedent-js'

interface SearchClientState {
  clientList: ClientList[]
  selectedClient: Nullable<ClientList>
  selectedChatClients: Nullable<ClientList[]>
  clientChatComponentRef: Nullable<ClientChatComponent>

  modalMap: { [id: string]: Modal }
  addressFormModalRef: Nullable<AddressFormWindowComponent>
  addressFormWindowRef: Nullable<AddressFormWindowComponent>
  clientFormModalRef: Nullable<ClientNewFormComponent>,
  clientFormWindowRef: Nullable<ClientNewFormComponent>,

  avialableBranchList: Branch[]
  pickUpBranchList: Branch[]
  selectedAddress: Nullable<Address>
  scheduleOrderDropdownRef: Nullable<ScheduleOrderDropdown>
  scheduleButtonRef: Nullable<HTMLElement>,
  cartWarningShown: boolean,
}

interface MenuState {
  divisionList: Division[]
  divisionGroupList: DivisionGroup[]
  selectedDivisionGroup: Nullable<DivisionGroup>
  selectedProduct: Nullable<Product>
  menuScroll: number
  menuBodyContainerRef: Nullable<HTMLElement>
}

@Options({
  name: 'NewOrder',
  components: {
    ClientSearchComponent,
    ClientAccordionListComponent,
    ClientChatComponent,

    GenericModal,
    FormModal,
    FakeModal,
    FakeFormModal,
    SelectAddressComponent,
    SelectPickupBranchComponent,
    SelectBranchComponent,
    NoCoverageBranchComponent,
    AddressFormWindowComponent,
    ClientNewFormComponent,

    BranchInfoListComponent,
    HistoryOrdersComponent,

    ScheduleOrderDropdown,
    MenuAccordionList,
    MenuDivisionGroupList,
    ProductDetail,

    CartDetail
  }
})
export default class NewOrderComponent extends Vue {
  context = setup(() => {
    const store = useStore()
    const emitter = inject<Emitter<CustomEvents>>('emitter')

    const searchSate = reactive<SearchClientState>({
      clientList: [],
      selectedClient: null,
      selectedChatClients: [],
      clientChatComponentRef: null,
      modalMap: {},
      addressFormModalRef: null,
      addressFormWindowRef: null,
      clientFormModalRef: null,
      clientFormWindowRef: null,
      avialableBranchList: [],
      pickUpBranchList: [],
      selectedAddress: null,
      scheduleOrderDropdownRef: null,
      scheduleButtonRef: null,
      cartWarningShown: false
    })

    onMounted(() => {
      const modalIds = [
        'kt_modal_new_address',
        'kt_modal_new_client',
        'kt_modal_select_branch',
        'kt_modal_no_coverage_branch',
        'kt_modal_select_pickup_branch',
        'kt_modal_select_address'
      ]

      modalIds.forEach((id) => {
        searchSate.modalMap[id] = new Modal(
          document.getElementById(id)
        )
      })

      store.dispatch(Actions.CART_CLEAR_ALL)
    })

    onBeforeUnmount(() => {
      store.dispatch(Actions.HIDE_ALL_MODALS)
    })

    const currentServiceType = computed<ServiceType>(
      () => store.getters.currentServiceType
    )

    const currentModalId = computed<string>(
      () => store.getters.currentModalId
    )

    const currentChat = computed<Nullable<Chat>>(
      () => store.getters.currentChat
    )

    const showModal = (id: string) => {
      store.dispatch(Actions.SHOW_MODAL, id)

      if (currentServiceType.value === ServiceType.Chat) {
        return
      }

      const targetModal = searchSate.modalMap[id]
      targetModal?.show()
    }

    const hideAllModals = () => {
      store.dispatch(Actions.HIDE_ALL_MODALS)

      if (currentServiceType.value === ServiceType.Chat) {
        return
      }

      Object
        .values(searchSate.modalMap)
        .forEach((modal) => modal?.hide())
    }

    const showModalAsColumn = computed<boolean>(() => {
      if (currentServiceType.value !== ServiceType.Chat) {
        return false
      }

      return !!currentModalId.value
    })

    const cartConfig = computed<CartConfigInfo>(
      () => store.getters.currentConfig
    )

    const cart = computed<Cart>(() => store.getters.currentCart)

    const onAddressSelect = (client: ClientList, address: Address) => {
      BranchService.getBranchListForLocation(address)
        .then((data: BranchData) => {
          if (cartConfig.value?.Client?.UserId !== client.UserId) {
            searchSate.cartWarningShown = false
          }

          searchSate.selectedClient = client
          searchSate.selectedAddress = address

          if (data.BranchList?.length === 1) {
            const branch = data.BranchList[0]
            if (branch.BranchCallCenterIsAllowed) {
              hideAllModals()
              store.dispatch(Actions.UPDATE_DELIVERY_CONFIG, {
                Branch: branch,
                Address: searchSate.selectedAddress,
                OrderScheduleInterval: null,
                Client: searchSate.selectedClient
              })

              return
            }
          }

          if (data.BranchList?.length === 0) {
            hideAllModals()
            showModal('kt_modal_no_coverage_branch')
            return
          }
          searchSate.avialableBranchList = data.BranchList
          hideAllModals()
          showModal('kt_modal_select_branch')
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const onPickupSelected = (client: ClientList) => {
      if (!client) {
        return
      }

      BranchService.getPickupBranchList()
        .then((data: BranchData) => {
          if (cartConfig.value?.Client?.UserId !== client.UserId) {
            searchSate.cartWarningShown = false
          }

          searchSate.selectedClient = client
          searchSate.pickUpBranchList = data.BranchList
          showModal('kt_modal_select_pickup_branch')
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const onDeliverySelected = (client: ClientList) => {
      if (!client) {
        return
      }

      searchSate.selectedClient = client
      showModal('kt_modal_select_address')
    }

    const onNewAddressSelected = (client: ClientList) => {
      hideAllModals()
      searchSate.addressFormModalRef?.context?.init(client)
      searchSate.addressFormWindowRef?.context?.init(client)
      showModal('kt_modal_new_address')
    }

    const onNewAddressCancel = () => {
      hideAllModals()
      searchSate.addressFormModalRef?.context?.dispose()
      searchSate.addressFormWindowRef?.context?.dispose()
    }

    const onSelectClient = (chat: Chat, clients: ClientList[]) => {
      searchSate.selectedChatClients = clients
      showModal('kt_modal_select_clients')
    }

    const onSelectAddClient = (phone: string) => {
      searchSate.clientFormModalRef?.context?.init(phone)
      searchSate.clientFormWindowRef?.context?.init(phone)
      showModal('kt_modal_new_client')
    }

    const onNewClientCancel = () => {
      hideAllModals()
      searchSate.clientFormModalRef?.context?.dispose()
      searchSate.clientFormWindowRef?.context?.dispose()
    }

    const onAddressAdded = (client: ClientList, address: Address) => {
      const targetClient = searchSate.clientList.find(
        (c) => c.UserId === client.UserId
      )

      targetClient?.AddressList?.push(address)

      hideAllModals()
      searchSate.addressFormModalRef?.context?.dispose()
      searchSate.addressFormWindowRef?.context?.dispose()
      searchSate.clientChatComponentRef?.context?.update()

      onAddressSelect(client, address)
    }

    const onClientAdded = (client: ClientList, address: Address) => {
      searchSate.clientList.unshift(client)

      hideAllModals()
      searchSate.addressFormModalRef?.context?.dispose()
      searchSate.addressFormWindowRef?.context?.dispose()
      searchSate.clientChatComponentRef?.context?.update()

      if (address?.AddressId) {
        client.AddressList?.push(address)
        onAddressSelect(client, address)
      }
    }

    const onBranchSelected = (branch: Branch) => {
      hideAllModals()

      store.dispatch(Actions.UPDATE_DELIVERY_CONFIG, {
        Branch: branch,
        Address: searchSate.selectedAddress,
        OrderScheduleInterval: null,
        Client: searchSate.selectedClient
      })
    }

    const onPickUpBranchSelected = (branch: Branch) => {
      hideAllModals()

      store.dispatch(Actions.UPDATE_PICKUP_CONFIG, {
        Branch: branch,
        OrderScheduleInterval: null,
        Client: searchSate.selectedClient
      })
    }

    const onAsapSelected = () => {
      store.dispatch(Actions.UPDATE_SCHEDULE_DATE, null)
    }

    const onScheduleSelected = (client: ClientList) => {
      if (!searchSate.scheduleButtonRef) {
        return
      }

      if (!client) {
        return
      }

      const cartConfig = store.getters.currentConfig
      searchSate.scheduleOrderDropdownRef?.context?.init(cartConfig)

      const component = MenuComponent.getInstance(searchSate.scheduleButtonRef)
      component.show(searchSate.scheduleButtonRef)
    }

    const onScheduleDateSelected = (
      scheduleInterval: BranchScheduleInterval
    ) => {
      store.dispatch(Actions.UPDATE_SCHEDULE_DATE, scheduleInterval)

      if (!searchSate.scheduleButtonRef) {
        return
      }

      const component = MenuComponent.getInstance(searchSate.scheduleButtonRef)
      component.hide(searchSate.scheduleButtonRef)
    }

    const onScheduleCancel = () => {
      if (!searchSate.scheduleButtonRef) {
        return
      }

      const component = MenuComponent.getInstance(searchSate.scheduleButtonRef)
      component.hide(searchSate.scheduleButtonRef)
    }

    const menuState = reactive<MenuState>({
      divisionList: [],
      divisionGroupList: [],
      selectedDivisionGroup: null,
      selectedProduct: null,
      menuScroll: 0,
      menuBodyContainerRef: null
    })

    const onDivisionGroupSelected = (divisionGroup: DivisionGroup) => {
      menuState.menuScroll = menuState.menuBodyContainerRef?.scrollTop ?? 0
      menuState.menuBodyContainerRef?.scroll(0, 0)

      menuState.selectedDivisionGroup = divisionGroup
      menuState.divisionList = divisionGroup?.DivisionList ?? []
    }

    const onMenuProductSelected = (product: Product) => {
      menuState.menuScroll = menuState.menuBodyContainerRef?.scrollTop ?? 0
      menuState.menuBodyContainerRef?.scroll(0, 0)

      menuState.selectedProduct = product
    }

    const onDivisionGroupBack = () => {
      menuState.selectedDivisionGroup = null
      setTimeout(() => {
        menuState.menuBodyContainerRef?.scroll(0, menuState.menuScroll)
      }, 0)
    }

    const onMenuProductBack = () => {
      menuState.selectedProduct = null
      setTimeout(() => {
        menuState.menuBodyContainerRef?.scroll(0, menuState.menuScroll)
      }, 0)
    }

    const clearCart = () => {
      store
        .dispatch(Actions.CART_EMPTY)
        .then(() => {
          // ignore
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const sendCartConfirmation = () => {
      if (!currentChat.value) {
        return
      }

      if (cart.value.ProductList.length === 0) {
        return
      }

      Swal.fire({
        text: '¿Estas seguro de mandar un mensaje con la confirmación del pedido?',
        icon: 'warning',
        buttonsStyling: false,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        confirmButtonText: 'Cambiar',
        customClass: {
          cancelButton: 'btn fw-bold btn-light-danger',
          confirmButton: 'btn fw-bold btn-light-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(currentChat.value, cart.value.ProductList)

          const products = cart.value.ProductList.map((product) => {
            const modifiers = product.ModifierList.map((item) => {
              return `
                - ${item.ItemName} ${item.ItemQuantity > 1 ? `x${item.ItemQuantity}` : ''}
              `
            })

            return dedent`
              ${product.ProductQuantity}x ${product.ProductName}
              ${modifiers.join('\n')}
            `
          })

          const message = dedent`
            Confirmación de pedido:

            ${products.join('\n')}

            Subtotal: $${cart.value.OrderSubTotal?.toFixed(2)}
            Envío: $${cart.value.OrderChargeFee?.toFixed(2)}
            Descuento: $${cart.value.OrderDiscount?.toFixed(2)}

            Total: $${cart.value.OrderTotal?.toFixed(2)}
          `

          chatService
            .sendMessage(currentChat.value?.ChatId, message)
            .then((data: MessageSendResponseData) => {
              if (!data.Message) {
                return
              }

              searchSate.clientChatComponentRef?.context?.update()
            })
            .catch((error: Error) => {
              Swal.fire({
                text: error.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Cerrar',
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              })
            })
        }
      })
    }

    watch(() => currentServiceType.value, () => {
      store.dispatch(Actions.HIDE_ALL_MODALS)
    })

    watch(
      [
        () => cartConfig.value?.Branch?.BranchId,
        () => cartConfig.value?.Location?.Latitude,
        () => cartConfig.value?.Location?.Longitude,
        () => cartConfig.value?.OrderIsPickUp,
        () => cartConfig.value?.OrderDate
      ],
      () => {
        if (!cartConfig.value?.Branch) {
          return
        }

        const workspace = store.getters.currentWorkspace as AppWorkspace
        if (workspace.AppHasDivisonGroups) {
          menuService
            .getFullMenuWithGroups(cartConfig.value)
            .then((data: MenuDivisionGroupData) => {
              menuState.divisionGroupList = data.DivisionGroupList
            })
            .catch((error: Error) => {
              Swal.fire({
                text: error.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Cerrar',
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              })
            })
        } else {
          menuService
            .getFullMenu(cartConfig.value)
            .then((data: MenuData) => {
              menuState.divisionList = data.DivisionList
            })
            .catch((error: Error) => {
              Swal.fire({
                text: error.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Cerrar',
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              })
            })
        }

        store
          .dispatch(Actions.UPDATE_CART)
          .then((cart: Nullable<Cart>) => {
            if (!searchSate.cartWarningShown && (cart?.ProductList?.length ?? 0) > 0) {
              searchSate.cartWarningShown = true

              Swal.fire({
                text: 'Este usuario ya cuenta con un carrito previo. ¿Deseas continuar o iniciar uno nuevo?',
                icon: 'warning',
                buttonsStyling: false,
                cancelButtonText: 'Continuar con carrito previo',
                showCancelButton: true,
                confirmButtonText: 'Continuar con nuevo carrito',
                customClass: {
                  cancelButton: 'btn fw-bold btn-light-success',
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  return store.dispatch(Actions.CART_EMPTY)
                }
              })
            }
          })
          .catch((error: Error) => {
            Swal.fire({
              text: error.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Cerrar',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger'
              }
            })
          })
      }
    )

    emitter?.on('clearOrder', () => {
      // searchSate.searchString = ''
      // searchSate.searchExecuted = false

      searchSate.clientList = []
      searchSate.selectedClient = null
      searchSate.avialableBranchList = []
      searchSate.pickUpBranchList = []
      searchSate.selectedAddress = null

      menuState.divisionList = []
      menuState.divisionGroupList = []
      menuState.selectedDivisionGroup = null
      menuState.selectedProduct = null
    })

    return {
      ...toRefs(searchSate),
      ...toRefs(menuState),

      ServiceType,
      currentServiceType,
      currentModalId,
      currentChat,
      showModalAsColumn,
      cartConfig,
      cart,

      onAddressSelect,
      onSelectClient,
      onSelectAddClient,
      onNewClientCancel,
      onDeliverySelected,
      onPickupSelected,
      onNewAddressSelected,
      onNewAddressCancel,
      onAddressAdded,
      onClientAdded,
      onBranchSelected,
      onPickUpBranchSelected,
      onAsapSelected,
      onScheduleSelected,
      onScheduleDateSelected,
      onScheduleCancel,

      onDivisionGroupSelected,
      onMenuProductSelected,
      onDivisionGroupBack,
      onMenuProductBack,
      sendCartConfirmation,
      clearCart
    }
  })
}
