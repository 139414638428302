enum PaymentGatewayType {
  openpay = 'openpay',
  holacash = 'holacash',
  webpay = 'webpay',
  stripe = 'stripe',
  evo = 'evo'
}

class PaymentGatewayConfig {
  static readonly allowedGateways = [
    'openpay',
    'holacash',
    'stripe'
  ]
}

export { PaymentGatewayConfig, PaymentGatewayType }
