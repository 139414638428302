
import { Division, DivisionGroup } from '@/services/menu/MenuData'
import { reactive, toRefs } from '@vue/reactivity'
import { computed } from 'vue'
import { Options, Vue, setup, prop } from 'vue-class-component'

class MenuDivisionGroupListProps {
  divisionGroupList = prop<DivisionGroup[]>({})
}

interface MenuState {
  menuSearch: string
}

@Options({
  name: 'menu-divisiongroup-list',
  emits: ['division-group-selected']
})
export default class MenuDivisionGroupList extends Vue.with(MenuDivisionGroupListProps) {
  context = setup(() => {
    const menuState = reactive<MenuState>({
      menuSearch: ''
    })

    const filterDivision = (searchString: string) => {
      return (division: DivisionGroup) => {
        if (!searchString) {
          return true
        }

        const divisionMath =
          division.DivisionGroupName?.toLowerCase().includes(
            searchString.toLowerCase()
          ) ?? false

        return divisionMath
      }
    }

    const filteredDivisionList = computed<DivisionGroup[]>(() => {
      const divisions = this.$props.divisionGroupList ?? []
      return divisions
        .map((division) => new DivisionGroup().from(division))
        .filter(filterDivision(menuState.menuSearch))
    })

    const onDivisionGroupSelected = (divisionGroup: DivisionGroup) => {
      this.$emit('division-group-selected', divisionGroup)
    }

    return {
      ...toRefs(menuState),

      filteredDivisionList,
      onDivisionGroupSelected
    }
  })
}
