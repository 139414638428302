import { Nullable } from '@/core/utils/CustomTypes'
import Message from './Message'

export enum ChatType {
  Active = 'active',
  Waiting = 'waiting',
  Other = 'other',
  Bot = 'bot'
}

export interface Channel {
  ChannelId: Nullable<string>
  ChannelName: Nullable<string>
  ChannelColor: Nullable<string>
}

export interface ChatStatus {
  ChatStatusId: Nullable<string>
  ChatStatusName: Nullable<string>
  ChatStatusColor: Nullable<string>
}

export default interface Chat {
  ChatId: Nullable<number>
  ChatCreateDate: Nullable<string>
  ChatUpdateDate: Nullable<string>
  ChatName: Nullable<string>
  ChatClientName: Nullable<string>
  ChatClientNumber: Nullable<string>
  ChatAccountNumber: Nullable<string>
  ChatMessageCount: Nullable<number>

  Channel: Nullable<Channel>
  ChatStatus: Nullable<ChatStatus>

  ChatLastMessage: Nullable<Message>
  MessageList: Nullable<Message[]>
  ChatType: ChatType
}
