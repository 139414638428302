import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "accordion-item" }
const _hoisted_2 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuProductGroupItem = _resolveComponent("MenuProductGroupItem")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h2", {
      class: "accordion-header",
      id: `${_ctx.accordionId}_header_${_ctx.division.DivisionId}`
    }, [
      _createVNode("button", {
        class: ["accordion-button fs-4 fw-bold", { collapsed: _ctx.index !== 0 }],
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": `#${_ctx.accordionId}_body_${_ctx.division.DivisionId}`,
        "aria-expanded": "true",
        "aria-controls": `${_ctx.accordionId}_body_${_ctx.division.DivisionId}`
      }, _toDisplayString(_ctx.division.DivisionName), 11, ["data-bs-target", "aria-controls"])
    ], 8, ["id"]),
    _createVNode("div", {
      id: `${_ctx.accordionId}_body_${_ctx.division.DivisionId}`,
      class: ["accordion-collapse collapse", { show: _ctx.index === 0 }],
      "aria-labelledby": `${_ctx.accordionId}_header_${_ctx.division.DivisionId}`,
      "data-bs-parent": `#${_ctx.accordionId}`
    }, [
      _createVNode("div", _hoisted_2, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.division.DivisionProductGroupList, (group, index) => {
          return (_openBlock(), _createBlock(_component_MenuProductGroupItem, {
            key: index,
            group: group,
            index: index,
            onProductSelected: _ctx.context.onProductSelected
          }, null, 8, ["group", "index", "onProductSelected"]))
        }), 128))
      ])
    ], 10, ["id", "aria-labelledby", "data-bs-parent"])
  ]))
}