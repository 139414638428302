import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "\r\n        input-with-select\r\n        el-input el-input-group el-input-group--append el-input-group--prepend\r\n      " }
const _hoisted_3 = { class: "separator my-10 mb-5 mt-5" }
const _hoisted_4 = { class: "d-flex flex-column align-items-sm-center" }
const _hoisted_5 = { class: "symbol symbol-125px symbol-2by3" }
const _hoisted_6 = { class: "d-flex flex-row-fluid align-items-center my-lg-0 me-2" }
const _hoisted_7 = { class: "flex-grow-1 my-lg-0 my-2 me-2" }
const _hoisted_8 = {
  href: "#",
  class: "text-gray-800 fw-bolder text-hover-primary fs-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("div", _hoisted_2, [
      _createVNode(_component_el_input, {
        placeholder: "Buscar marca",
        modelValue: _ctx.context.menuSearch,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.context.menuSearch = $event)),
        clearable: ""
      }, {
        append: _withCtx(() => [
          _createVNode(_component_el_button, { icon: "el-icon-search" })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 512), [
      [_vShow, _ctx.context.filteredDivisionList?.length > 0]
    ]),
    _withDirectives(_createVNode("div", _hoisted_3, null, 512), [
      [_vShow, _ctx.context.filteredDivisionList?.length > 0]
    ]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.context.filteredDivisionList, (divisionGroup, index) => {
      return (_openBlock(), _createBlock("div", {
        key: divisionGroup.DivisionGroupId,
        class: ["col-md-6", { 'mt-7': index > 1 }],
        onClick: ($event: any) => (_ctx.context.onDivisionGroupSelected(divisionGroup))
      }, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", {
              class: "symbol-label",
              style: `background-image: url(${divisionGroup.DivisionGroupImage})`
            }, null, 4)
          ]),
          _createVNode("div", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              _createVNode("a", _hoisted_8, _toDisplayString(divisionGroup.DivisionGroupName), 1)
            ])
          ])
        ])
      ], 10, ["onClick"]))
    }), 128))
  ]))
}