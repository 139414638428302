
import { Options, Vue, setup, prop } from 'vue-class-component'

class FormModalProps {
  id = prop<string>({})
  title = prop<string>({})
  showClose = prop<string>({})
  dialogClass = prop<string>({})
  bodyClass = prop<string>({})
}

@Options({
  name: 'form-modal',
  components: {},
  emits: []
})
export default class FormModal extends Vue.with(FormModalProps) {
  context = setup(() => {
    return {}
  })
}
