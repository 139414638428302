
import { Modifier, ModifierItem, Product } from '@/services/menu/MenuData'
import { reactive, toRefs } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import { Options, Vue, setup, prop } from 'vue-class-component'
import { useStore } from 'vuex'

import {
  ProductAddElement,
  ModifierItemAddElement
} from '@/services/cart/ProductInputData'
import { ModifierElement } from './ModifierItemDetail.vue'

import cartService from '@/services/cart/cart.service'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { CartConfigInfo } from '@/store/modules/CartModule'
import ProductResponseData from '@/services/cart/ProductResponseData'
import { Actions } from '@/store/enums/StoreEnums'
import { CartProduct, CartProductItem } from '@/services/cart/Cart'
import { Nullable } from '@/core/utils/CustomTypes'

// import MenuGroupListItem from './ModifierGroupListItem.vue'

class ProductDetailProps {
  product = prop<Product>({})
  divisionGroupId = prop<Nullable<number>>({})
  orderProduct = prop<CartProduct>({})
}

interface ProductDetailStateInfo {
  selectedModifierItemNode: Nullable<ModifierItem>
  modifierList: ModifierElement[]

  productQuantity: number
  productComments: string
}

@Options({
  name: 'product-detail',
  emits: ['product-added'],
  components: {
    // MenuGroupListItem
  }
})
export default class ProductDetail extends Vue.with(ProductDetailProps) {
  context = setup(() => {
    const store = useStore()

    const getModifier = (modifier: Modifier): ModifierElement => {
      const modifierElement = modifier as ModifierElement
      const selectedItems =
        this.$props.orderProduct?.ModifierList.map((item) => {
          const itemAddElement: CartProductItem = {
            OrderProductItemId: item.OrderProductItemId,
            ItemId: item.ItemId,
            ItemExternalId: item.ItemExternalId,
            ModifierId: item.ModifierId,
            ModifierExternalId: item.ModifierExternalId,
            ModifierName: item.ModifierName,
            ItemName: item.ItemName,
            ItemPrice: item.ItemPrice,
            ItemQuantity: item.ItemQuantity,
            OrderProductItemParentId: item.OrderProductItemParentId,
            ItemParentId: item.ItemParentId,
            Modifiers: []
          }

          return itemAddElement
        }) ?? []

      selectedItems.forEach((item) => {
        item.Modifiers = selectedItems.filter(
          (i) => item.OrderProductItemId === i.OrderProductItemParentId
        )
      })

      modifierElement.selectedItems = selectedItems
        .filter(
          (item) =>
            !item.OrderProductItemParentId &&
            item.ModifierId === modifierElement.ModifierId
        )
        .map((item): ModifierItemAddElement => {
          return {
            ItemId: item.ItemId,
            ItemName: item.ItemName,
            ItemQuantity: item.ItemQuantity,
            ItemPrice: item.ItemPrice,
            ModifierId: item.ModifierId,
            Modifiers: item.Modifiers
          }
        })

      return modifierElement
    }

    const generateModifierList = (
      modifierList: Modifier[]
    ): ModifierElement[] => {
      return modifierList.map(getModifier) ?? []
    }

    const productDetailState = reactive<ProductDetailStateInfo>({
      selectedModifierItemNode: null,
      modifierList: generateModifierList(
        this.$props.product?.ModifierList ?? []
      ),
      productQuantity: this.$props.orderProduct?.ProductQuantity ?? 1,
      productComments: this.$props.orderProduct?.ProductComments ?? ''
    })

    watch([() => this.$props.product, () => this.$props.orderProduct], () => {
      if (this.$props.product) {
        productDetailState.modifierList = generateModifierList(
          this.$props.product?.ModifierList ?? []
        )

        productDetailState.productQuantity =
          this.$props.orderProduct?.ProductQuantity ?? 1
        productDetailState.productComments =
          this.$props.orderProduct?.ProductComments ?? ''

        productDetailState.selectedModifierItemNode = null
        return
      }

      productDetailState.productQuantity = 1
      productDetailState.productComments = ''
      productDetailState.selectedModifierItemNode = null
    })

    const showItemModifierView = computed<boolean>(
      () => productDetailState.selectedModifierItemNode != null
    )
    const productTotalValue = computed<number>(() => {
      const modifierTotalValue = productDetailState.modifierList.reduce(
        (agg, modifier) => {
          return (
            agg + (modifier.modifierGroupRef?.context.modifierGroupValue ?? 0)
          )
        },
        0
      )

      return (
        productDetailState.productQuantity *
        (modifierTotalValue + (this.$props.product?.ProductPrice ?? 0))
      )
    })

    const onItemModifiersOpen = (item: ModifierItem, modifier: Modifier) => {
      item.ModifierId = modifier.ModifierId
      productDetailState.selectedModifierItemNode = item
    }

    const onItemModifiersClosed = (item: ModifierItem, modifier: Modifier) => {
      productDetailState.selectedModifierItemNode = null
    }

    const addProduct = (product: ProductAddElement) => {
      const cartConfig = store.getters.currentConfig as CartConfigInfo
      cartService
        .addProduct(product, cartConfig?.Client)
        .then((_: ProductResponseData) => {
          store.dispatch(Actions.UPDATE_CART)
          this.$emit('product-added', product)
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const updateProduct = (
      product: ProductAddElement,
      orderProduct: CartProduct
    ) => {
      const cartConfig = store.getters.currentConfig as CartConfigInfo
      cartService
        .updateProduct(orderProduct.OrderProductId, product, cartConfig?.Client)
        .then((_: ProductResponseData) => {
          store.dispatch(Actions.UPDATE_CART)
          this.$emit('product-added', product)
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const onProductAdded = () => {
      const validModifierList = productDetailState.modifierList.filter(
        (modifier) => modifier.modifierGroupRef?.context.modifierGroupIsValid
      )

      if (validModifierList.length !== productDetailState.modifierList.length) {
        return Swal.fire({
          text: 'Debes verificar los modificadores obligatorios',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      const modifierList = validModifierList.flatMap(
        (modifier): ModifierItemAddElement[] => {
          return (
            modifier.modifierGroupRef?.context.itemList
              .filter((item) => item.ItemIsSelected)
              .map<ModifierItemAddElement>((item) => {
                return {
                  ItemId: item.ItemId,
                  ItemName: item.ItemName,
                  ItemPrice: item.ItemPrice,
                  ItemQuantity: item.ItemSelectedQuantity,
                  ModifierId: modifier.ModifierId,
                  Modifiers: item.Modifiers
                }
              }) ?? []
          )
        }
      )

      const productAddElement: ProductAddElement = {
        ProductId: this.$props.product?.ProductId,
        ProductName: this.$props.product?.ProductName,
        ProductQuantity: productDetailState.productQuantity,
        ProductPrice: this.$props.product?.ProductPrice,
        ProductComments: productDetailState.productComments,
        DivisionGroupId: this.$props.divisionGroupId,
        Modifiers: modifierList
      }

      if (this.$props.orderProduct) {
        productAddElement.DivisionGroupId = this.$props.orderProduct?.DivisionGroup?.DivisionGroupId
        updateProduct(productAddElement, this.$props.orderProduct)
      } else {
        addProduct(productAddElement)
      }
    }

    return {
      ...toRefs(productDetailState),
      showItemModifierView,
      productTotalValue,

      onItemModifiersOpen,
      onItemModifiersClosed,
      onProductAdded
    }
  })
}
