
import { Division, Product } from '@/services/menu/MenuData'
import { Options, Vue, setup } from 'vue-class-component'

import MenuProductGroupItem from './MenuProductGroupItem.vue'

@Options({
  name: 'division-accordion-item',
  props: ['accordionId', 'index', 'division'],
  emits: ['product-selected'],
  components: {
    MenuProductGroupItem
  }
})
export default class MenuDivisionAccordionItem extends Vue {
  division!: Division

  context = setup(() => {
    const onProductSelected = (product: Product) => {
      this.$emit('product-selected', product)
    }

    return {
      onProductSelected
    }
  })
}
