
import { CartProduct } from '@/services/cart/Cart'
import { ProductAddElement } from '@/services/cart/ProductInputData'
import { Product } from '@/services/menu/MenuData'
import { Options, Vue, setup, prop } from 'vue-class-component'

import ProductDetail from '@/components/menu/ProductDetail.vue'
import { reactive, toRefs } from '@vue/reactivity'
import { Nullable } from '@/core/utils/CustomTypes'

class CartProductDetailProps {
    orderProduct = prop<CartProduct>({})
    product = prop<Product>({})
}

interface CartProductDetailStateInfo {
  productDetailRef: Nullable<ProductDetail>
}

@Options({
  name: 'cart-product-detail-modal',
  components: {
    ProductDetail
  },
  emits: ['product-updated']
})
export default class CartProductDetailModal extends Vue.with(CartProductDetailProps) {
  context = setup(() => {
    const cartProductState = reactive<CartProductDetailStateInfo>({
      productDetailRef: null
    })

    const onProductAdded = (product: ProductAddElement) => {
      this.$emit('product-updated', product)
    }

    const refresh = () => {
      // cartProductState.productDetailRef?.$forceUpdate()
    }

    return {
      ...toRefs(cartProductState),
      refresh,

      onProductAdded
    }
  })
}
