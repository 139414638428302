
import { reactive, toRefs, computed, watch } from 'vue'
import {
  Division,
  Product,
  ProductGroup,
  ProductGroupContent
} from '@/services/menu/MenuData'
import { Options, Vue, setup, prop } from 'vue-class-component'
import MenuDivisionAccordionItem from './MenuDivisionAccordionItem.vue'

class MenuAccordionListProps {
  divisionList = prop<Division[]>({})
}

interface MenuItemsState {
  menuSearch: string
}

@Options({
  nanme: 'menu-accordion-list',
  emits: ['product-selected'],
  components: {
    MenuDivisionAccordionItem
  }
})
export default class MenuAccordionList extends Vue.with(MenuAccordionListProps) {
  context = setup(() => {
    const menuState = reactive<MenuItemsState>({
      menuSearch: ''
    })

    const filterProduct = (product: ProductGroupContent) => {
      if (!menuState.menuSearch) {
        return true
      }

      let productMatch =
        product.GroupProductAlias?.toLowerCase().includes(
          menuState.menuSearch.toLowerCase()
        ) ?? false

      productMatch =
        productMatch ||
        (product.Product?.ProductName?.toLowerCase().includes(
          menuState.menuSearch.toLowerCase()
        ) ??
          false)

      productMatch =
        productMatch ||
        (product.Product?.ProductDescription?.toLowerCase().includes(
          menuState.menuSearch.toLowerCase()
        ) ??
          false)

      return productMatch
    }

    const filterProductGroup = (group: ProductGroup) => {
      if (!menuState.menuSearch) {
        return true
      }

      let groupMatch =
        group.GroupName?.toLowerCase().includes(
          menuState.menuSearch.toLowerCase()
        ) ?? false

      groupMatch =
        groupMatch ||
        (group.GroupDescription?.toLowerCase().includes(
          menuState.menuSearch.toLowerCase()
        ) ??
          false)

      if (groupMatch) {
        return true
      }

      group.GroupProductList = (group.GroupProductList ?? [])
        .map((groupContent) => new ProductGroupContent().from(groupContent))
        .filter(filterProduct)

      return (group.GroupProductList?.length ?? 0) > 0
    }

    const filterDivision = (searchString: string) => {
      return (division: Division) => {
        if (!searchString) {
          return true
        }

        const divisionMath =
          division.DivisionName?.toLowerCase().includes(
            searchString.toLowerCase()
          ) ?? false

        if (divisionMath) {
          return true
        }

        division.DivisionProductGroupList = (
          division.DivisionProductGroupList ?? []
        )
          .map((group) => new ProductGroup().from(group))
          .filter(filterProductGroup)

        return (division.DivisionProductGroupList?.length ?? 0) > 0
      }
    }

    const filteredDivisionList = computed<Division[]>(() => {
      const divisions = (this.$props as MenuAccordionList).divisionList ?? []
      return divisions
        .map((division) => new Division().from(division))
        .filter(filterDivision(menuState.menuSearch))
    })

    const onProductSelected = (product: Product) => {
      this.$emit('product-selected', product)
    }

    watch(() => this.$props.divisionList, () => {
      menuState.menuSearch = ''
    })

    return {
      ...toRefs(menuState),
      onProductSelected,

      filteredDivisionList
    }
  })
}
