
import { reactive, toRefs } from '@vue/reactivity'
import { Options, Vue, setup } from 'vue-class-component'

import { Modal } from 'bootstrap'
import { computed, onMounted } from '@vue/runtime-core'
import { Nullable } from '@/core/utils/CustomTypes'
import CartPlaceInput from '@/services/cart/CartPlaceInput'
import cartService from '@/services/cart/cart.service'
import { useStore } from 'vuex'
import { CartConfigInfo } from '@/store/modules/CartModule'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'

import { SpringSpinner } from 'epic-spinners'
import CartPaymentLinkResponseData from '@/services/cart/CartPaymentLinkResponse'
import CartPaymentStatusResponse from '@/services/cart/CartPaymentStatusResponse'
import { ServiceType } from '@/store/modules/ServiceTypeModule'
import Chat from '@/services/chat/Chat'

interface CartPaymentLinkModalStateInfo {
  orderPlace: Nullable<CartPlaceInput>
  modal: Nullable<Modal>
  interval: Nullable<any>,

  paymentIntentId: Nullable<number>,
  paymentLink: Nullable<string>,
  textIsCopied: boolean,

  sendLinkToChat: boolean,

  clientPhone: string,
  sendLinkToPhone: boolean,

  clientEmail: string,
  sendLinkToEmail: boolean,

  sendButtonElement: Nullable<HTMLElement>
}

@Options({
  name: 'cart-payment-link-modal',
  components: {
    SpringSpinner
  },
  emits: ['success', 'reject', 'error', 'cancel']
})
export default class CartPaymentLinkModal extends Vue {
  context = setup(() => {
    const store = useStore()

    const currentServiceType = computed<ServiceType>(
      () => store.getters.currentServiceType
    )

    const currentChat = computed<Chat>(
      () => store.getters.currentChat
    )

    const cartPaymentLinkModalState = reactive<CartPaymentLinkModalStateInfo>({
      orderPlace: null,
      modal: null,
      interval: null,
      paymentIntentId: null,
      paymentLink: null,
      textIsCopied: false,
      sendLinkToChat: currentServiceType.value === ServiceType.Chat,
      clientPhone: '',
      sendLinkToPhone: false,
      clientEmail: '',
      sendLinkToEmail: false,
      sendButtonElement: null
    })

    const dispose = () => {
      cartPaymentLinkModalState.interval && clearInterval(cartPaymentLinkModalState.interval)
      cartPaymentLinkModalState.interval = null
      cartPaymentLinkModalState.orderPlace = null
      cartPaymentLinkModalState.paymentLink = null
      cartPaymentLinkModalState.textIsCopied = false

      cartPaymentLinkModalState.clientPhone = ''
      cartPaymentLinkModalState.sendLinkToPhone = false

      cartPaymentLinkModalState.clientEmail = ''
      cartPaymentLinkModalState.sendLinkToEmail = false
    }

    const hide = () => {
      cartPaymentLinkModalState.modal?.hide()
      dispose()
    }

    const show = (orderPlace: CartPlaceInput) => {
      dispose()
      cartPaymentLinkModalState.orderPlace = orderPlace
      cartPaymentLinkModalState.modal?.show()

      const cartInfo = store.getters
        .currentConfig as Nullable<CartConfigInfo>

      if (!cartInfo || !orderPlace) {
        return
      }

      cartPaymentLinkModalState.clientPhone = cartInfo.Client?.ClientPhone ?? ''
      cartPaymentLinkModalState.clientEmail = ''

      cartService.getPaymentLink(orderPlace, cartInfo, currentChat.value?.ChatId)
        .then((data: CartPaymentLinkResponseData) => {
          cartPaymentLinkModalState.paymentIntentId = data.OrderPaymentIntentId
          cartPaymentLinkModalState.paymentLink = data.OrderPaymentLink

          cartPaymentLinkModalState.interval = setInterval(() => {
            cartService.getPaymentStatus(orderPlace.OrderId, cartInfo)
              .then((data: CartPaymentStatusResponse) => {
                if (data.Order?.OrderPaymentStatusId === 'paid') {
                  clearInterval(cartPaymentLinkModalState.interval)
                  hide()
                  this.$emit('success')
                }

                if (data.Order?.OrderPaymentStatusId === 'rejected' && data.Order?.OrderPaymentIntentReloadLink) {
                  clearInterval(cartPaymentLinkModalState.interval)
                  hide()
                  this.$emit('reject', data.Order)
                } else if (data.Order?.OrderPaymentStatusId === 'rejected') {
                  // Do something in case of error in payment but the link is still valid
                }
              })
              .catch(() => {
                // ignore
              })
          }, 15 * 1000)
        })
        .catch((error: Error) => {
          setTimeout(() => {
            hide()
          }, 500)
          this.$emit('error', error)
        })
    }

    const cancel = () => {
      const orderId = cartPaymentLinkModalState.orderPlace?.OrderId
      hide()

      const cartInfo = store.getters.currentConfig as Nullable<CartConfigInfo>
      if (!cartInfo || !orderId) {
        this.$emit('cancel')
        return
      }

      cartService.getPaymentStatus(orderId, cartInfo)
        .then((data: CartPaymentStatusResponse) => {
          if (data.Order?.OrderPaymentStatusId === 'paid') {
            this.$emit('success')
          } else {
            this.$emit('cancel')
          }
        })
        .catch(() => {
          this.$emit('cancel')
        })
    }

    onMounted(() => {
      const modalElement = document.getElementById('kt_modal_cart_payment_link')
      const config = {
        backdrop: 'static',
        keyboard: false
      }

      cartPaymentLinkModalState.modal = new Modal(modalElement, config)
    })

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text)
      cartPaymentLinkModalState.textIsCopied = true

      setTimeout(() => {
        cartPaymentLinkModalState.textIsCopied = false
      }, 3 * 1000)
    }

    const isAllowedToSend = () : boolean => {
      if (!cartPaymentLinkModalState.paymentIntentId) {
        return false
      }

      if (!cartPaymentLinkModalState.paymentLink) {
        return false
      }

      if (!cartPaymentLinkModalState.sendLinkToChat &&
          !cartPaymentLinkModalState.sendLinkToPhone &&
          !cartPaymentLinkModalState.sendLinkToEmail) {
        return false
      }

      if (!cartPaymentLinkModalState.clientPhone &&
          !cartPaymentLinkModalState.clientEmail) {
        return false
      }

      return true
    }

    const sendPaymentLink = () => {
      if (!cartPaymentLinkModalState.paymentIntentId) {
        return
      }

      if (
        cartPaymentLinkModalState.sendButtonElement?.getAttribute('data-kt-indicator') === 'on'
      ) {
        return
      }

      cartPaymentLinkModalState.sendButtonElement?.setAttribute('data-kt-indicator', 'on')

      const clientPhone: Nullable<string> = cartPaymentLinkModalState.sendLinkToPhone
        ? cartPaymentLinkModalState.clientPhone
        : null

      const clientEmail: Nullable<string> = cartPaymentLinkModalState.sendLinkToEmail
        ? cartPaymentLinkModalState.clientEmail
        : null

      const chatId: Nullable<number> = cartPaymentLinkModalState.sendLinkToChat
        ? currentChat.value.ChatId
        : null

      cartService.sendPaymentLink(
        cartPaymentLinkModalState.paymentIntentId,
        clientPhone,
        clientEmail,
        chatId)
        .then(() => {
          cartPaymentLinkModalState.sendButtonElement?.removeAttribute('data-kt-indicator')
          Swal.fire({
            text: 'Enlace enviado exitosamente',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-success'
            }
          })
        })
        .catch((error: Error) => {
          cartPaymentLinkModalState.sendButtonElement?.removeAttribute('data-kt-indicator')
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    return {
      ...toRefs(cartPaymentLinkModalState),
      currentServiceType,
      ServiceType,

      show,
      hide,
      cancel,

      copyToClipboard,
      isAllowedToSend,
      sendPaymentLink
    }
  })

  show (orderPlace: CartPlaceInput) : void {
    this.context?.show(orderPlace)
  }

  hide () : void {
    this.context?.hide()
  }
}
