import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-items-sm-start" }
const _hoisted_2 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_3 = {
  href: "#",
  class: "text-gray-800 fw-bolder text-hover-primary fs-6"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_5 = { class: "badge badge-light-warning" }
const _hoisted_6 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex align-items-center flex-shrink-0"
}
const _hoisted_8 = {
  key: 0,
  class: "flex-wrap mt-3"
}
const _hoisted_9 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: { 'mt-7': _ctx.index > 0 }
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", {
            class: "symbol-label",
            style: `background-image: url(${_ctx.group.GroupImage})`
          }, null, 4)
        ]),
        _createVNode("div", {
          class: ["d-flex flex-row-fluid align-items-center my-lg-0 me-2", { 'flex-wrap': _ctx.group.GroupType === 'group_multiple' }]
        }, [
          _createVNode("div", {
            class: ["flex-grow-1 my-lg-0 my-2 me-2", { 'w-100': _ctx.group.GroupType === 'group_multiple' }],
            onClick: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.group.GroupType === 'group_single' &&
            _ctx.context.onProductSelected(_ctx.group.GroupProductList?.[0]?.Product)
          ))
          }, [
            _createVNode("a", _hoisted_3, _toDisplayString(_ctx.group.GroupName), 1),
            (_ctx.group.GroupType === 'group_single' && _ctx.group.GroupProductList?.[0]?.Product?.PromotionList?.length > 0)
              ? (_openBlock(), _createBlock("div", _hoisted_4, [
                  _createVNode("span", _hoisted_5, _toDisplayString(_ctx.group.GroupProductList?.[0]?.Product?.PromotionList?.[0]?.PromotionName), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode("span", _hoisted_6, _toDisplayString(_ctx.group.GroupDescription), 1)
          ], 2),
          (_ctx.group.GroupType === 'group_single')
            ? (_openBlock(), _createBlock("div", _hoisted_7, [
                _createVNode("a", {
                  href: "#",
                  class: "btn btn-light btn-active-light-primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (
              _ctx.context.onProductSelected(_ctx.group.GroupProductList?.[0]?.Product)
            ))
                }, " $" + _toDisplayString(_ctx.group.GroupProductList?.[0]?.Product?.ProductPrice?.toFixed(2)), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ], 2),
    (_ctx.group.GroupType === 'group_multiple')
      ? (_openBlock(), _createBlock("div", _hoisted_8, [
          _createVNode("div", _hoisted_9, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.group.GroupProductList, (groupContent, index) => {
              return (_openBlock(), _createBlock("div", {
                key: index,
                class: "col-md-4"
              }, [
                _createVNode("a", {
                  href: "#",
                  class: "\r\n            btn btn-light btn-active-light-primary\r\n            d-flex\r\n            align-items-center\r\n            justify-content-center\r\n            h-100\r\n          ",
                  onClick: ($event: any) => (_ctx.context.onProductSelected(groupContent?.Product))
                }, _toDisplayString(groupContent?.GroupProductAlias) + " $" + _toDisplayString(groupContent?.Product?.ProductPrice?.toFixed(2)), 9, ["onClick"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}