
import Branch from '@/services/branches/Branch'
import Cart, { CartProduct } from '@/services/cart/Cart'
import Address from '@/services/clients/Address'
import { CartConfigInfo } from '@/store/modules/CartModule'
import DateUtils from '@/utils/DateUtils'
import { reactive, toRefs } from '@vue/reactivity'
import { Options, setup, Vue, prop } from 'vue-class-component'
import { useStore } from 'vuex'

import { Modal } from 'bootstrap'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

import { CustomEvents, Nullable } from '@/core/utils/CustomTypes'

import CartOrderProduct from './CartOrderProduct.vue'
import CartProductDetailModal from './modals/CartProductDetailModal.vue'
import CartPlaceModal from './modals/CartPlaceModal.vue'
import CartPaymentLinkModal from './modals/CartPaymentLinkModal.vue'

import { computed, inject, onMounted, watch } from 'vue'
import menuService from '@/services/menu/menu.service'
import MenuProductData from '@/services/menu/MenuProductData'
import { Product } from '@/services/menu/MenuData'
import cartService from '@/services/cart/cart.service'
import { Actions, Constans } from '@/store/enums/StoreEnums'

import CartPlaceInput from '@/services/cart/CartPlaceInput'
import { Emitter } from 'mitt'
import { CartPaymentStatus } from '@/services/cart/CartPaymentStatusResponse'
import { PaymentGatewayConfig } from '@/utils/payment/models/PaymentGatewayType'
import Chat from '@/services/chat/Chat'
import { PaymentProviderFactory } from '@/utils/payment/PaymentProvider'

class CartDetailProps {
  cart = prop<Cart>({})
  cartConfig = prop<CartConfigInfo>({})
}

interface CartStateInfo {
  references: string
  comments: string
  paymentTypeId: Nullable<number>
  orderTip: number
  orderUsedPoints: number
  productDetailModal: Nullable<Modal>
  productDetailModalRef: Nullable<CartProductDetailModal>
  selectedOrderProduct: Nullable<CartProduct>
  selectedProduct: Nullable<Product>

  couponInputValue: string
  cartPlaceButtonRef: Nullable<HTMLElement>
  cartPlaceModalRef: Nullable<CartPlaceModal>
  cartPaymentLinkModalRef: Nullable<CartPaymentLinkModal>
}

@Options({
  name: 'cart-detail-component',
  components: {
    CartOrderProduct,
    CartProductDetailModal,
    CartPlaceModal,
    CartPaymentLinkModal
  }
})
export default class CartDetailComponent extends Vue.with(CartDetailProps) {
  context = setup(() => {
    const store = useStore()
    const emitter = inject<Emitter<CustomEvents>>('emitter')

    const cartState = reactive<CartStateInfo>({
      references: '',
      comments: '',
      paymentTypeId: null,
      orderTip: 0,
      orderUsedPoints: 0,
      productDetailModal: null,
      productDetailModalRef: null,
      selectedOrderProduct: null,
      selectedProduct: null,

      couponInputValue: '',
      cartPlaceButtonRef: null,
      cartPlaceModalRef: null,
      cartPaymentLinkModalRef: null
    })

    const isOnlinePaymentAllowed = computed(() => {
      return this.$props.cart?.Branch?.BranchAcceptsCard &&
        (!this.$props.cart?.Branch?.PaymentGatewayId ||
        PaymentGatewayConfig.allowedGateways.includes(this.$props.cart?.Branch?.PaymentGatewayId))
    })

    watch(
      () => this.$props.cartConfig?.Address?.AddressId,
      () => {
        cartState.references =
          this.$props.cartConfig?.Address?.AddressReference ?? ''
      }
    )

    onMounted(() => {
      cartState.productDetailModal = new Modal(
        document.getElementById('kt_modal_product_detail')
      )
    })

    watch(
      () => cartState.paymentTypeId,
      () => {
        const paymentType = this.$props.cart?.PaymentTypeList.find(
          (p) => p.PaymentTypeId === cartState.paymentTypeId
        )

        if (!paymentType) {
          return
        }

        store.dispatch(Actions.UPDATE_CART_TOTALS, { paymentIsOnline: false, usePoins: false })
      }
    )

    function getFullAddress (address?: Address): string {
      if (!address) {
        return ''
      }

      const noInt = address.AddressNoInt ? `INT: ${address.AddressNoInt}` : ''

      return `${address.AddressStreet} ${address.AddressNoExt} ${noInt} ${address.AddressNeighborhood} ${address.AddressZipCode}`
    }

    function getBranchAddress (branch?: Branch) {
      if (!branch) {
        return ''
      }

      return `${branch.BranchStreet} ${branch.BranchNoExt} ${branch.BranchNeighborhood} ${branch.BranchZipCode}`
    }

    const onItemSelected = (product: CartProduct) => {
      const cartConfig = store.getters.currentConfig as CartConfigInfo
      cartState.selectedOrderProduct = null
      cartState.selectedProduct = null

      menuService
        .getProduct(product.ProductId, cartConfig)
        .then((data: MenuProductData) => {
          cartState.selectedOrderProduct = product
          cartState.selectedProduct = data.MenuProduct ?? null

          cartState.productDetailModalRef?.context.refresh()
          cartState.productDetailModal?.show()
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const onItemUpdated = () => {
      cartState.selectedOrderProduct = null
      cartState.selectedProduct = null

      cartState.productDetailModal?.hide()
    }

    const addCoupon = () => {
      if (!cartState.couponInputValue) {
        return
      }

      const cartConfig = store.getters.currentConfig as CartConfigInfo
      cartService
        .addCoupon(cartState.couponInputValue, cartConfig)
        .then(() => {
          store.dispatch(Actions.UPDATE_CART)
          cartState.couponInputValue = ''
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const placeCart = () => {
      if (!cartState.cartPlaceButtonRef) {
        return
      }

      if (
        cartState.cartPlaceButtonRef?.getAttribute('data-kt-indicator') === 'on'
      ) {
        return
      }

      cartState.cartPlaceButtonRef?.setAttribute('data-kt-indicator', 'on')

      const cart = this.$props.cart
      const cartConfig = this.$props.cartConfig

      if (!cart || !cartConfig || !cart.OrderId) {
        cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')

        return Swal.fire({
          text: 'Carrito no encontrado',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      if (!cart.Branch || !cart.BranchId) {
        cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')

        return Swal.fire({
          text: cart.BranchName ?? 'Carrito no encontrado',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      if (!cartState.paymentTypeId) {
        cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')

        return Swal.fire({
          text: 'Debes seleccionar un método de pago',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      const orderInput: CartPlaceInput = {
        OrderId: cart.OrderId,
        OrderComments: cartState.comments,
        PaymentTypeId: cartState.paymentTypeId,
        OrderTip: cartState.orderTip,
        OrderAddressId: cartConfig.Address?.AddressId,
        OrderAddressStreet: cartConfig.Address?.AddressStreet ?? '',
        OrderAddressNoExt: cartConfig.Address?.AddressNoExt ?? '',
        OrderAddressNoInt: cartConfig.Address?.AddressNoInt ?? '',
        OrderAddressZipCode: cartConfig.Address?.AddressZipCode ?? '',
        OrderAddressPhone: cartConfig.Client?.ClientPhone ?? '',
        OrderAddressNeighborhood: cartConfig.Address?.AddressNeighborhood ?? '',
        OrderAddressMunicipality: cartConfig.Address?.AddressMunicipality ?? '',
        OrderAddressExtension: cartConfig.Address?.AddressExtension ?? '',
        OrderAddressReference: cartState.references,
        OrderAddressCity: cartConfig.Address?.AddressMunicipality ?? '',
        OrderAddresssState: cartConfig.Address?.AddressState ?? '',
        OrderAddressGoogleId: cartConfig.Address?.AddressGoogleId,
        CardId: null,
        Latitude: cartConfig.Location?.Latitude ?? 0,
        Longitude: cartConfig.Location?.Longitude ?? 0,
        OrderPlatform: 'web',
        OrderPlatformVersion: `${Constans.APP_VERSION}`,
        OrderUsePoints: false,
        OrderDinersCount: 1,
        BranchId: cart.BranchId,
        OrderIsPickUp: cartConfig.OrderIsPickUp,
        OrderDate: cartConfig.OrderDate,
        DeviceId: null
      }

      if (orderInput.OrderIsPickUp) {
        orderInput.OrderAddressStreet = cartConfig.Branch?.BranchStreet ?? ''
        orderInput.OrderAddressNoExt = cartConfig.Branch?.BranchNoExt ?? ''
        orderInput.OrderAddressNoInt = cartConfig.Branch?.BranchNoInt ?? ''
        orderInput.OrderAddressZipCode = cartConfig.Branch?.BranchZipCode ?? ''
        orderInput.OrderAddressNeighborhood = cartConfig.Branch?.BranchNeighborhood ?? ''
        orderInput.OrderAddressMunicipality = cartConfig.Branch?.BranchMunicipality ?? ''
        orderInput.OrderAddressReference = `El usuario recogerá en sucursal ${cartConfig.Branch?.BranchName}`
        orderInput.OrderAddressCity = cartConfig.Branch?.BranchMunicipality ?? ''
        orderInput.OrderAddresssState = cartConfig.Branch?.BranchState ?? ''

        orderInput.Latitude = cartConfig.Branch?.BranchLatitude ?? 0
        orderInput.Longitude = cartConfig.Branch?.BranchLongitude ?? 0
      }

      cartService
        .validatePlace(orderInput, cartConfig)
        .then(() => {
          cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')
          cartState.cartPlaceModalRef?.show(orderInput)
        })
        .catch((error: Error) => {
          cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const showPaymentLink = async () => {
      cartState.paymentTypeId = null
      if (!cartState.cartPlaceButtonRef) {
        return
      }

      if (
        cartState.cartPlaceButtonRef?.getAttribute('data-kt-indicator') === 'on'
      ) {
        return
      }

      cartState.cartPlaceButtonRef?.setAttribute('data-kt-indicator', 'on')

      const cart = this.$props.cart
      const cartConfig = this.$props.cartConfig

      if (!cart || !cartConfig || !cart.OrderId) {
        cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')

        return Swal.fire({
          text: 'Carrito no encontrado',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      if (!cart.Branch || !cart.BranchId) {
        cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')

        return Swal.fire({
          text: cart.BranchName ?? 'Carrito no encontrado',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      const orderInput: CartPlaceInput = {
        OrderId: cart.OrderId,
        OrderComments: cartState.comments,
        PaymentTypeId: null,
        OrderTip: cartState.orderTip,
        OrderAddressId: cartConfig.Address?.AddressId,
        OrderAddressStreet: cartConfig.Address?.AddressStreet ?? '',
        OrderAddressNoExt: cartConfig.Address?.AddressNoExt ?? '',
        OrderAddressNoInt: cartConfig.Address?.AddressNoInt ?? '',
        OrderAddressZipCode: cartConfig.Address?.AddressZipCode ?? '',
        OrderAddressPhone: cartConfig.Client?.ClientPhone ?? '',
        OrderAddressNeighborhood: cartConfig.Address?.AddressNeighborhood ?? '',
        OrderAddressMunicipality: cartConfig.Address?.AddressMunicipality ?? '',
        OrderAddressExtension: cartConfig.Address?.AddressExtension ?? '',
        OrderAddressReference: cartState.references,
        OrderAddressCity: cartConfig.Address?.AddressMunicipality ?? '',
        OrderAddresssState: cartConfig.Address?.AddressState ?? '',
        OrderAddressGoogleId: cartConfig.Address?.AddressGoogleId,
        CardId: null,
        Latitude: cartConfig.Location?.Latitude ?? 0,
        Longitude: cartConfig.Location?.Longitude ?? 0,
        OrderPlatform: 'web',
        OrderPlatformVersion: `${Constans.APP_VERSION}`,
        OrderUsePoints: false,
        OrderDinersCount: 1,
        BranchId: cart.BranchId,
        OrderIsPickUp: cartConfig.OrderIsPickUp,
        OrderDate: cartConfig.OrderDate,
        DeviceId: null
      }

      if (orderInput.OrderIsPickUp) {
        orderInput.OrderAddressStreet = cartConfig.Branch?.BranchStreet ?? ''
        orderInput.OrderAddressNoExt = cartConfig.Branch?.BranchNoExt ?? ''
        orderInput.OrderAddressNoInt = cartConfig.Branch?.BranchNoInt ?? ''
        orderInput.OrderAddressZipCode = cartConfig.Branch?.BranchZipCode ?? ''
        orderInput.OrderAddressNeighborhood = cartConfig.Branch?.BranchNeighborhood ?? ''
        orderInput.OrderAddressMunicipality = cartConfig.Branch?.BranchMunicipality ?? ''
        orderInput.OrderAddressReference = `El usuario recogerá en sucursal ${cartConfig.Branch?.BranchName}`
        orderInput.OrderAddressCity = cartConfig.Branch?.BranchMunicipality ?? ''
        orderInput.OrderAddresssState = cartConfig.Branch?.BranchState ?? ''

        orderInput.Latitude = cartConfig.Branch?.BranchLatitude ?? 0
        orderInput.Longitude = cartConfig.Branch?.BranchLongitude ?? 0
      }

      if (this.$props.cart?.Branch?.PaymentGatewayId) {
        const paymentUtil = PaymentProviderFactory.GetProvider({
          type: this.$props.cart?.Branch?.PaymentGatewayId
        })

        orderInput.DeviceId = await paymentUtil?.getDeviceId('')
      }

      cartState.cartPlaceButtonRef?.removeAttribute('data-kt-indicator')
      cartState.cartPaymentLinkModalRef?.show(orderInput)
    }

    const onPlaceSuccess = () => {
      Swal.fire({
        text: 'Pedido levantado exitosamente',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Cerrar',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-success'
        }
      }).then(() => {
        store.dispatch(Actions.CART_CLEAR_ALL)
        emitter?.emit('clearOrder', '')
      })
    }

    const onPlaceError = (error: Error) => {
      Swal.fire({
        text: error.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Cerrar',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-danger'
        }
      })
    }

    const onPlaceCancel = () => {
      // pending
    }

    const onPlaceRejected = (orderStatus: CartPaymentStatus) => {
      Swal.fire({
        text: orderStatus?.OrderPaymentSpecificStatusMessage,
        icon: 'error',
        buttonsStyling: false,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        confirmButtonText: 'Generar nueva liga de pago',
        customClass: {
          cancelButton: 'btn fw-bold btn-light-danger',
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          return showPaymentLink()
        }
      })
    }

    return {
      ...toRefs(cartState),
      isOnlinePaymentAllowed,

      getFullAddress,
      getBranchAddress,
      onItemSelected,
      onItemUpdated,

      addCoupon,
      placeCart,
      showPaymentLink,

      onPlaceSuccess,
      onPlaceError,
      onPlaceCancel,
      onPlaceRejected,

      ...DateUtils.toLiteral()
    }
  })
}
