
import Address from '@/services/clients/Address'
import { Options, Vue, setup } from 'vue-class-component'

import AddressListItem from '@/components/address/AddressListItem.vue'
import ClientList from '@/services/clients/ClientList'

@Options({
  name: 'select-address-component',
  components: {
    AddressListItem
  },
  props: ['address-list', 'client'],
  emits: ['address-selected', 'new-address-selected']
})
export default class SelectAddressComponent extends Vue {
  addressList!: Address[]
  client!: ClientList

  context = setup(() => {
    const onAddressSelected = (client: ClientList, address: Address) => {
      this.$emit('address-selected', client, address)
    }

    const onNewAddressSelected = (client: ClientList) => {
      this.$emit('new-address-selected', client)
    }

    return {
      onAddressSelected,
      onNewAddressSelected
    }
  })
}
