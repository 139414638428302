import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = { class: "card h-100 d-flex flex-column" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "card-body d-flex flex-column flex-grow-1 " }
const _hoisted_8 = {
  key: 0,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createVNode("div", _hoisted_5, [
          (_ctx.showClose)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: "btn btn-sm btn-icon btn-active-color-primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.context.closeModal()))
              }, [
                _createVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Close.svg" })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode("div", _hoisted_7, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.context.hasFooter)
        ? (_openBlock(), _createBlock("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}