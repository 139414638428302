/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardInput } from '../models/card.input'
import { PaymentProvider, PaymentProviderData } from '../PaymentProvider'
import { CardOutput } from '../models/card.output.js'
import { Nullable } from '@/core/utils/CustomTypes'

export class StripeUtil implements PaymentProvider {
  private publicKey: string
  private stripe: any

  public constructor (data: PaymentProviderData) {
    if (!data.paymentStripePublicKey) {
      this.publicKey = ''
      return
    }

    this.publicKey = data.paymentStripePublicKey
    this.stripe = (window as any).Stripe

    this.stripe.setPublishableKey(this.publicKey)
  }

  needCardExtraData (): boolean {
    return false
  }

  needPaymentLink (): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getDeviceId (formId: string): Promise<Nullable<string>> {
    return null
  }

  async createToken (card: CardInput): Promise<CardOutput> {
    const form = document.createElement('form')

    form.innerHTML += `<input type="text" size="20" data-stripe="number" value="${card.CardNumber}">`
    form.innerHTML += `<input type="text" size="2" data-stripe="exp_month" value="${card.CardExpirationMonth}">`
    form.innerHTML += `<input type="text" size="2" data-stripe="exp_year" value="${card.CardExpirationYear}">`
    form.innerHTML += `<input type="text" size="4" data-stripe="cvc" value="${card.Cardcvv}">`

    return new Promise((resolve, reject) => {
      this.stripe.createToken(form, (_status: number, response: any) => {
        if (response.error) {
          const message = response.error.message
          return reject(new Error(message as string))
        }

        const cartOutput: CardOutput = {
          CardToken: response.id,
          CardLast4: response.card.last4,
          CardBrand: '',
          CardExpirationYear: response.card.exp_year,
          CardExpirationMonth: response.card.exp_month,
          CardCountry: response.card.country
        }

        switch (response.card.brand.toUpperCase()) {
          case 'VISA':
          case 'VISA ELECTRON':
            cartOutput.CardBrand = 'VISA'
            break
          case 'MASTERCARD':
            cartOutput.CardBrand = 'Mastercard'
            break
          case 'AMEX':
          case 'AMERICAN EXPRESS':
            cartOutput.CardBrand = 'Amex'
            break
          default:
            cartOutput.CardBrand = response.card.brand
            break
        }

        resolve(cartOutput)
      })
    })
  }
}
