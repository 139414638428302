/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { CardInput } from '../models/card.input'
import { PaymentProvider, PaymentProviderData } from '../PaymentProvider'
import { CardOutput } from '../models/card.output.js'
import { v4 as uuidV4 } from 'uuid'
import { IpUtil } from '@/utils/ip.uil'
import { Buffer } from 'buffer'
import axios from 'axios'

interface AintifraudData {
  device_id: string
  'user-agent-string': string
  os_type: string
  screen_resolution_width: number
  screen_resolution_height: number
  user_timezone?: string
  ip_address: string
}

export class HolacashUtil implements PaymentProvider {
  private isProduction: boolean
  private publicKey: string

  public constructor (data: PaymentProviderData) {
    this.isProduction = data.paymentHolaCashIsProduction ?? false
    this.publicKey = data.paymentHolaCashPublicKey ?? ''
  }

  private timezone (date = new Date()): string {
    const timezoneOffset = date.getTimezoneOffset()
    const sign = timezoneOffset < 0 ? '+' : '-'
    let minutes = Math.abs(timezoneOffset)
    const hours = Math.floor(minutes / 60)
    minutes = minutes - 60 * hours
    return sign + ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)
  }

  needCardExtraData (): boolean {
    return true
  }

  needPaymentLink (): boolean {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getDeviceId (formId: string): Promise<string> {
    const ip = await IpUtil.GetIp()
    const data: AintifraudData = {
      device_id: uuidV4(),
      'user-agent-string': navigator.userAgent,
      os_type: navigator.platform,
      screen_resolution_width: screen.width,
      screen_resolution_height: screen.height,
      ip_address: ip
    }

    try {
      data.user_timezone = this.timezone()
    } catch (err) {
      // ignore
    }

    const jsonString = JSON.stringify(data)
    const base64 = Buffer.from(jsonString).toString('base64')

    return base64
  }

  async createToken (card: CardInput): Promise<CardOutput> {
    const environmentUrl = this.isProduction
      ? 'https://live.api.holacash.mx/v2'
      : 'https://sandbox.api.holacash.mx/v2'

    const tokenData = {
      consumer_details: {
        contact: {
          email: card.CardEmail
        },
        name: {
          first_name: card.CardHolderName
        }
      },
      credential: {
        credit_or_debit_card: {
          card_number: card.CardNumber,
          card_validation_code: card.Cardcvv,
          expiration_month: card.CardExpirationMonth,
          expiration_year:
            new Date()
              .getFullYear()
              .toString()
              .substring(0, 2) + card.CardExpirationYear
        },
        payment_method: {
          method: 'credit_or_debit_card'
        }
      }
    }

    const aintiFraudToken = await this.getDeviceId('')
    const response = await axios
      .post(`${environmentUrl}/tokenization/payment_token`, tokenData, {
        headers: {
          'X-Api-Client-Key': this.publicKey,
          'X-Cash-Anti-Fraud-Metadata': aintiFraudToken
        }
      })
      .catch(err => {
        return Promise.reject(
          new Error(err.response?.data?.message ?? err.response?.data?.detail)
        )
      })

    const details = response.data?.token_details?.additional_details ?? []
    const brand = details.find((data: any) => data.name === 'card_brand')
    const lastFour = details.find(
      (data: any) => data.name === 'card_last_four_digits'
    )
    const year = details.find((data: any) => data.name === 'expiration_year')
    const month = details.find((data: any) => data.name === 'expiration_month')

    const cartOutput: CardOutput = {
      CardToken: response.data?.token_details?.token,
      CardLast4: lastFour?.data,
      CardBrand: '',
      CardExpirationYear: year?.data,
      CardExpirationMonth: month?.data,
      CardCountry: 'MX',
      DeviceId: aintiFraudToken
    }

    switch (brand?.data?.toUpperCase()) {
      case 'VISA':
      case 'VISA ELECTRON':
        cartOutput.CardBrand = 'VISA'
        break
      case 'MASTERCARD':
        cartOutput.CardBrand = 'Mastercard'
        break
      case 'AMEX':
      case 'AMERICAN EXPRESS':
        cartOutput.CardBrand = 'Amex'
        break
      default:
        cartOutput.CardBrand = brand?.data
        break
    }

    return cartOutput
  }
}
