import { Nullable } from '@/core/utils/CustomTypes'
import { CardInput } from './models/card.input'
import { CardOutput } from './models/card.output'
import { HolacashUtil } from './providers/holacash.util'
import { OpenpayUtil } from './providers/openpay.util'
import { StripeUtil } from './providers/stripe.util'

export interface PaymentProvider {
  getDeviceId(formId: string): Promise<Nullable<string>>
  createToken(card: CardInput): Promise<CardOutput>
  needCardExtraData(): boolean
  needPaymentLink(): boolean
}

export const allowedPaymentGateways = ['openpay', 'holacash', 'stripe']

export interface PaymentProviderData {
  type: Nullable<string>

  // OpenPay
  paymentOpenPayIsProduction?: boolean
  paymentOpenPayMerchantId?: string
  paymentOpenPayPublicKey?: string
  paymentOpenPayIsGroupTokenization?: boolean

  // Holacash
  paymentHolaCashIsProduction?: boolean
  paymentHolaCashPublicKey?: string

  // Stripe
  paymentStripePublicKey?: string
}

export class PaymentProviderFactory {
  public static GetProvider (
    data: PaymentProviderData
  ): Nullable<PaymentProvider> {
    switch (data.type) {
      case 'openpay':
        return new OpenpayUtil(data)
      case 'holacash':
        return new HolacashUtil(data)
      case 'stripe':
        return new StripeUtil(data)
    }

    return null
  }
}
