
import { computed } from '@vue/runtime-core'
import { Options, Vue, setup, prop } from 'vue-class-component'

class GenericModalProps {
  id = prop<string>({})
  title = prop<string>({})
  showClose = prop<string>({})
  dialogClass = prop<string>({})
  bodyClass = prop<string>({})
}

@Options({
  name: 'generic-modal',
  components: {},
  emits: []
})
export default class GenericModal extends Vue.with(GenericModalProps) {
  context = setup(() => {
    const hasFooter = computed<boolean>(() => !!this.$slots?.footer)

    return {
      hasFooter
    }
  })
}
