import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = {
  key: 0,
  class: "modal-footer flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", {
    class: "modal fade",
    id: _ctx.id,
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createVNode("div", {
      class: ["modal-dialog", `${_ctx.dialogClass}`]
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", {
          class: ["modal-header", {
            'pb-0 border-0 justify-content-end': !_ctx.title
          }]
        }, [
          (_ctx.title)
            ? (_openBlock(), _createBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (_ctx.showClose)
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("span", _hoisted_4, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Close.svg" })
                ])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode("div", {
          class: ["modal-body scroll-y", `${_ctx.bodyClass}`]
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        (_ctx.context.hasFooter)
          ? (_openBlock(), _createBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 8, ["id"]))
}