import { Nullable } from "@/core/utils/CustomTypes";
import SerializableObject from "@/services/core/SerializableObject";

export enum AddressName {
    Home = 'Home',
    Work = 'Work',
    Other = 'other'
}

export enum AddressType {
    Favorite = 'favorite',
    Recent = 'recent'
}

export default class Address extends SerializableObject {
    AddressId?: Nullable<number>
    AddressTypeId?: Nullable<AddressType>
    AddressLat?: Nullable<number>
    AddressLng?: Nullable<number>
    AddressName?: Nullable<AddressName>
    AddressStreet?: Nullable<string>
    AddressNoExt?: Nullable<string>
    AddressNoInt?: Nullable<string>
    AddressNeighborhood?: Nullable<string>
    AddressMunicipality?: Nullable<string>
    AddressState?: Nullable<string>
    AddressZipCode?: Nullable<string>
    AddressReference?: Nullable<string>
    AddressPhone?: Nullable<string>
    AddressExtension?: Nullable<string>
    AddressGoogleDesc?: Nullable<string>
    AddressGoogleId?: Nullable<string>

    constructor(
        id?: number, 
        type?: AddressType, 
        lat?: number, 
        lng?: number, 
        name?: AddressName, 
        street?: string,
        noExt?: string,
        noInt?: string,
        neighborhood?: string,
        municipality?: string,
        state?: string,
        zipCode?: string,
        reference?: string,
        phone?: string,
        extension?: string,
        googleDesc?: string,
        googleId?: string) {
        super()

        this.AddressId = id ?? null
        this.AddressTypeId = type ?? null
        this.AddressLat = lat ?? null
        this.AddressLng = lng ?? null
        this.AddressName = name ?? null
        this.AddressStreet = street ?? null
        this.AddressNoExt = noExt ?? null
        this.AddressNoInt = noInt ?? null
        this.AddressNeighborhood = neighborhood ?? null
        this.AddressMunicipality = municipality ?? null
        this.AddressState = state ?? null
        this.AddressZipCode = zipCode ?? null
        this.AddressReference = reference ?? null
        this.AddressPhone = phone ?? null
        this.AddressExtension = extension ?? null
        this.AddressGoogleDesc = googleDesc ?? null
        this.AddressGoogleId = googleId ?? null
    }
}