
import ClientList from '@/services/clients/ClientList'
import { Options, prop, setup, Vue } from 'vue-class-component'
import { inject, reactive, toRefs } from 'vue'

import Address from '@/services/clients/Address'
import ClientSearchData from '@/services/clients/ClientSearchData'
import ClientSearchList from '@/components/client/ClientSearchList.vue'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { debounce } from 'debounce'

import ClientService from '@/services/clients/client.service'
import { CustomEvents } from '@/core/utils/CustomTypes'
import { Emitter } from 'mitt'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'

class ClientSearchComponentProps {
  clientList = prop<ClientList[]>({})
}

interface SearchClientState {
  searchString: string
  searchExecuted: boolean
  searchExecuting: boolean
}

@Options({
  name: 'client-search-component',
  components: {
    ClientSearchList
  },
  emits: ['address-selected', 'pickup-selected', 'new-address', 'add-client']
})
export default class ClientSearchComponent extends Vue.with(ClientSearchComponentProps) {
  context = setup(() => {
    const emitter = inject<Emitter<CustomEvents>>('emitter')
    const store = useStore()

    const searchSate = reactive<SearchClientState>({
      searchString: '',
      searchExecuted: false,
      searchExecuting: false
    })

    const search = () => {
      if (!searchSate.searchString) {
        return
      }

      if (!searchSate.searchString) {
        searchSate.searchExecuting = true
      }

      ClientService.searchClientList(searchSate.searchString)
        .then((data: ClientSearchData) => {
          this.$props.clientList?.splice(0, this.$props.clientList?.length ?? 0)
          data.ClientList?.forEach(item => this.$props.clientList?.push(item))

          searchSate.searchExecuted = true
          searchSate.searchExecuting = false
        })
        .catch((error: Error) => {
          searchSate.searchExecuting = false

          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    const onSearchInput = debounce(search, 2.5 * 1000)
    const onSearch = () => {
      onSearchInput.clear()
      search()
    }

    const onSelectAddClient = () => {
      var clientList = this.$props.clientList ?? []
      const phone = clientList.length > 0 ? '' : searchSate.searchString
      this.$emit('add-client', phone)
    }

    const onAddressSelect = (client: ClientList, address: Address) => {
      this.$emit('address-selected', client, address)
    }

    const onPickupSelected = (client: ClientList) => {
      this.$emit('pickup-selected', client)
    }

    const onNewAddressSelected = (client: ClientList) => {
      this.$emit('new-address', client)
    }

    emitter?.on('clearOrder', () => {
      searchSate.searchString = ''
      searchSate.searchExecuted = false
    })

    emitter?.on('userSelected', (phone) => {
      searchSate.searchString = phone ?? ''
      search()
      store.dispatch(Actions.SET_SERVICE_PHONE)
    })

    return {
      ...toRefs(searchSate),

      onSearch,
      onSelectAddClient,
      onAddressSelect,
      onPickupSelected,
      onNewAddressSelected
    }
  })
}
