import { CardInput } from '../models/card.input'
import { PaymentProvider, PaymentProviderData } from '../PaymentProvider'
import { CardOutput } from '../models/card.output.js'
import { Nullable } from '@/core/utils/CustomTypes'

export class OpenpayUtil implements PaymentProvider {
  private openpayGeneral: any
  private openpay: any

  public constructor (data: PaymentProviderData) {
    if (!data.paymentOpenPayPublicKey) return

    this.openpayGeneral = (window as any).OpenPay

    this.openpayGeneral.setSandboxMode(!data.paymentOpenPayIsProduction)
    this.openpayGeneral.setId(data.paymentOpenPayMerchantId)
    this.openpayGeneral.setApiKey(data.paymentOpenPayPublicKey)

    this.openpay = data.paymentOpenPayIsGroupTokenization
      ? this.openpayGeneral.Group
      : this.openpayGeneral

    this.openpay.setId(data.paymentOpenPayMerchantId)
    this.openpay.setApiKey(data.paymentOpenPayPublicKey)
  }

  needCardExtraData (): boolean {
    return false
  }

  needPaymentLink (): boolean {
    return false
  }

  async getDeviceId (formId: string): Promise<Nullable<string>> {
    if (!this.openpayGeneral) return null

    const deviceId = this.openpayGeneral.deviceData.setup(formId)
    return deviceId
  }

  async createToken (card: CardInput): Promise<CardOutput> {
    return new Promise((resolve, reject) => {
      this.openpay.token.create(
        {
          card_number: card.CardNumber,
          holder_name: card.CardHolderName,
          expiration_year: card.CardExpirationYear,
          expiration_month: card.CardExpirationMonth,
          cvv2: card.Cardcvv
        },
        (response: any) => {
          const cartOutput: CardOutput = {
            CardToken: response.data.id,
            CardLast4: response.data.card.card_number.substr(
              response.data.card.card_number.length - 4
            ),
            CardBrand: '',
            CardExpirationYear: response.data.card.expiration_year,
            CardExpirationMonth: response.data.card.expiration_month,
            CardCountry: 'MX'
          }

          switch (response.data.card.brand.toUpperCase()) {
            case 'VISA':
            case 'VISA ELECTRON':
              cartOutput.CardBrand = 'VISA'
              break
            case 'MASTERCARD':
              cartOutput.CardBrand = 'Mastercard'
              break
            case 'AMEX':
            case 'AMERICAN EXPRESS':
              cartOutput.CardBrand = 'Amex'
              break
            default:
              cartOutput.CardBrand = response.data.card.brand
              break
          }

          resolve(cartOutput)
        },
        (error: any) => {
          const message = error.data?.description ?? error.message
          reject(new Error(message as string))
        }
      )
    })
  }
}
