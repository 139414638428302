
import { Actions } from '@/store/enums/StoreEnums'
import { Options, Vue, setup, prop } from 'vue-class-component'
import { useStore } from 'vuex'

class FakeFormModalProps {
  id = prop<string>({})
  title = prop<string>({})
  showClose = prop<string>({})
  dialogClass = prop<string>({})
  bodyClass = prop<string>({})
}

@Options({
  name: 'fake-form-modal',
  components: {},
  emits: []
})
export default class FakeFormModal extends Vue.with(FakeFormModalProps) {
  context = setup(() => {
    const store = useStore()

    const closeModal = () => {
      store.dispatch(Actions.HIDE_ALL_MODALS)
    }

    return {
      closeModal
    }
  })
}
