
import { Options, prop, setup, Vue } from 'vue-class-component'
import Dropdown4 from '@/components/dropdown/Dropdown4.vue'
import { ScrollComponent } from '@/assets/ts/components'
import MessageIn from '@/components/messenger-parts/MessageIn.vue'
import MessageOut from '@/components/messenger-parts/MessageOut.vue'
import { computed, onMounted, onUpdated, reactive, ref, toRefs, watch } from 'vue'
import Chat from '@/services/chat/Chat'
import DateUtils from '@/utils/DateUtils'
import chatService from '@/services/chat/chat.service'
import MessageSendResponseData from '@/services/chat/MessageSendResponseData'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import ClientList from '@/services/clients/ClientList'
import { ToggleComponent } from '@/assets/ts/components/_ToggleComponent'
import { debounce } from 'debounce'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'

class ChatWindowProps {
  contact = prop<Chat>({})
  clients = prop<ClientList[]>({})
}

interface ChatState {
  showNewMessageBadge: boolean
}

@Options({
  name: 'chat-window',
  components: {
    MessageIn,
    MessageOut,
    Dropdown4
  },
  emits: [
    'back',
    'delivery-selected',
    'pickup-selected',
    'add-client',
    'select-client',
    'branch-view-selected',
    'order-view-selected'
  ]
})
export default class ChatWindowComponent extends Vue.with(ChatWindowProps) {
  context = setup(() => {
    const store = useStore()

    const messagesRef = ref<null | HTMLElement>(null)
    const messagesInRef = ref<null | HTMLElement>(null)
    const messagesOutRef = ref<null | HTMLElement>(null)

    const chatState = reactive<ChatState>({
      showNewMessageBadge: true
    })

    const refreshScroll = () => {
      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight
        }

        chatState.showNewMessageBadge = false
      }, 1)
    }

    const onScroll = debounce(() => {
      if (!messagesRef.value) {
        return
      }

      const currentMaxScrollTop = messagesRef.value.scrollHeight - messagesRef.value.offsetHeight
      if (currentMaxScrollTop - messagesRef.value.scrollTop <= 150) {
        chatState.showNewMessageBadge = false
      }
    }, 250)

    const newMessageText = ref('')

    const addNewMessage = () => {
      if (!newMessageText.value) {
        return
      }

      chatService
        .sendMessage(this.$props.contact?.ChatId, newMessageText.value)
        .then((data: MessageSendResponseData) => {
          if (!data.Message) {
            return
          }

          newMessageText.value = ''
          this.$props.contact?.MessageList?.push(data.Message)
          refreshScroll()
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    onMounted(() => {
      if (!messagesRef.value) {
        return
      }

      ScrollComponent.bootstrap()
      ToggleComponent.reinitialization()
      refreshScroll()
    })

    onUpdated(() => {
      ToggleComponent.reinitialization()
    })

    watch([
      () => this.$props.contact
    ], () => {
      refreshScroll()
    })

    watch([
      () => this.$props.contact?.MessageList
    ], (messages, oldMessages) => {
      if (!messagesRef?.value) {
        return
      }

      const oldMessageIds = oldMessages?.[0]?.map(m => m.MessageId) ?? []
      const newMessageCount = messages?.[0]
        ?.filter(m => !oldMessageIds.includes(m.MessageId))
        .length ?? 0

      const currentMaxScrollTop = messagesRef.value.scrollHeight - messagesRef.value.offsetHeight
      if (newMessageCount > 0 && currentMaxScrollTop - messagesRef.value.scrollTop <= 150) {
        refreshScroll()
      } else if (newMessageCount > 0) {
        chatState.showNewMessageBadge = true
      }
    })

    const hasMultipleClients = computed<boolean>(() => {
      return (this.$props.clients?.length ?? 0) > 1
    })

    const hasClients = computed<boolean>(() => {
      return (this.$props.clients?.length ?? 0) > 0
    })

    const onBackSelected = () => {
      this.$emit('back')
    }

    const onSelectClient = () => {
      this.$emit('select-client', this.$props.contact, this.$props.clients)
    }

    const onAddClientSelected = () => {
      const phone = this.$props.contact?.ChatClientNumber ?? ''
      const trimedPhone = phone.substring(phone.length - 10)
      this.$emit('add-client', trimedPhone)
    }

    const onDeliverySelected = () => {
      const client = this.$props.clients?.[0]
      this.$emit('delivery-selected', client)
    }

    const onPickUpSelected = () => {
      const client = this.$props.clients?.[0]
      this.$emit('pickup-selected', client)
    }

    const onBranchViewSelected = () => {
      store.dispatch(Actions.SHOW_MODAL, 'kt_chat_branch_info_list')
    }

    const onOrderViewSelected = () => {
      store.dispatch(Actions.SHOW_MODAL, 'kt_chat_order_info_list')
    }

    const onFileLoaded = debounce(() => {
      refreshScroll()
    }, 250)

    return {
      ...toRefs(chatState),
      messagesRef,
      newMessageText,
      messagesInRef,
      messagesOutRef,

      hasMultipleClients,
      hasClients,

      refreshScroll,
      onScroll,
      addNewMessage,
      onBackSelected,
      onSelectClient,
      onAddClientSelected,
      onDeliverySelected,
      onPickUpSelected,
      onBranchViewSelected,
      onOrderViewSelected,
      onFileLoaded,

      ...DateUtils.toLiteral()
    }
  })
}
