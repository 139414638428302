
import { CartProduct } from '@/services/cart/Cart'
import { CartConfigInfo } from '@/store/modules/CartModule'
import { Options, prop, setup, Vue } from 'vue-class-component'
import { useStore } from 'vuex'

import { debounce } from 'debounce'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

import cartService from '@/services/cart/cart.service'
import ProductResponseData from '@/services/cart/ProductResponseData'
import { Actions } from '@/store/enums/StoreEnums'

class CartOrderProductProps {
  product = prop<CartProduct>({})
}

@Options({
  name: 'cart-order-product',
  emits: ['item-selected'],
  components: {}
})
export default class CartOrderProductComponent extends Vue.with(
  CartOrderProductProps
) {
  context = setup(() => {
    const store = useStore()
    const onItemClick = (product: CartProduct, ev: Event) => {
      ev.stopPropagation()

      this.$emit('item-selected', product)
    }

    const onItemQuantityClick = (ev: Event) => {
      ev.stopPropagation()
    }

    const onItemQuantityChanged = debounce(
      (currentValue: number, oldValue: number) => {
        const cartConfig = store.getters.currentConfig as CartConfigInfo
        const product = this.$props.product
        if (!product) {
          return
        }

        cartService
          .updateProductQuantity(
            product.OrderProductId,
            currentValue,
            cartConfig.Client
          )
          .then(() => {
            store.dispatch(Actions.UPDATE_CART)
          })
          .catch((error: Error) => {
            setTimeout(() => {
              product.ProductQuantity = oldValue
            }, 0)

            Swal.fire({
              text: error.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Cerrar',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger'
              }
            })
          })
      },
      500
    )

    const onProductDelete = (product: CartProduct, ev: Event) => {
      ev.preventDefault()
      ev.stopPropagation()

      const cartConfig = store.getters.currentConfig as CartConfigInfo
      cartService
        .removeProduct(product.OrderProductId, cartConfig.Client)
        .then(() => {
          store.dispatch(Actions.UPDATE_CART)
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    return {
      onItemClick,
      onItemQuantityClick,
      onItemQuantityChanged,
      onProductDelete
    }
  })
}
