
import Branch from '@/services/branches/Branch'
import { computed } from 'vue'
import { Options, Vue, setup, prop } from 'vue-class-component'

class SelectPickupBranchProps {
  branches = prop<Branch[]>({})
}

@Options({
  name: 'select-pickup-branch',
  components: {},
  props: ['branches'],
  emits: ['branch-selected']
})
export default class SelectPickupBranchComponent extends Vue.with(SelectPickupBranchProps) {
  context = setup(() => {
    const allowedBranchList = computed(() => {
      return this.$props.branches
        ?.filter(branch => branch.BranchCallCenterIsAllowed)
    })

    const notAllowedBranchList = computed(() => {
      return this.$props.branches
        ?.filter(branch => !branch.BranchCallCenterIsAllowed)
    })

    const onBranchSelected = (branch: Branch) => {
      this.$emit('branch-selected', branch)
    }

    return {
      allowedBranchList,
      notAllowedBranchList,
      onBranchSelected
    }
  })
}
