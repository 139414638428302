import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "\r\n        input-with-select\r\n        el-input el-input-group el-input-group--append el-input-group--prepend\r\n      " }
const _hoisted_2 = { class: "separator my-10 mb-5 mt-5" }
const _hoisted_3 = {
  class: "accordion",
  id: "kt_accordion_menu_divisions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_input = _resolveComponent("el-input")
  const _component_MenuDivisionAccordionItem = _resolveComponent("MenuDivisionAccordionItem")

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode(_component_el_input, {
        placeholder: "Buscar producto y/o división",
        modelValue: _ctx.context.menuSearch,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.context.menuSearch = $event)),
        clearable: ""
      }, {
        append: _withCtx(() => [
          _createVNode(_component_el_button, { icon: "el-icon-search" })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 512), [
      [_vShow, _ctx.divisionList?.length > 0]
    ]),
    _withDirectives(_createVNode("div", _hoisted_2, null, 512), [
      [_vShow, _ctx.divisionList?.length > 0]
    ]),
    _createVNode("div", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.context.filteredDivisionList, (division, index) => {
        return (_openBlock(), _createBlock(_component_MenuDivisionAccordionItem, {
          key: division.DivisionId,
          division: division,
          index: index,
          accordionId: 'kt_accordion_menu_divisions',
          onProductSelected: _ctx.context.onProductSelected
        }, null, 8, ["division", "index", "onProductSelected"]))
      }), 128))
    ])
  ]))
}