
import { reactive, toRefs, watch } from 'vue'
import { Options, Vue, setup, prop } from 'vue-class-component'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { useStore } from 'vuex'

import Address, { AddressName } from '@/services/clients/Address'

import { Loader } from '@googlemaps/js-api-loader'
import ClientList from '@/services/clients/ClientList'
import { Nullable } from '@/core/utils/CustomTypes'
import AppWorkspace from '@/services/workspaces/AppWorkspace'
import { Constans } from '@/store/enums/StoreEnums'

class AddressFormProps {
  prefixId = prop<string>({})
  address = prop<Address>({})
  client = prop<ClientList>({})
  nameSelector = prop<boolean>({ default: false })
}

interface AddressNameItem {
  id: string
  name: string
  icon: string
  color: string
  isHovering: boolean
}

interface AddressModalState {
  addressNameList: AddressNameItem[]
  addressAutocompleteRef: Nullable<HTMLInputElement>
  mapDivRef: Nullable<HTMLElement>
  markerIsDragging: boolean
}

@Options({
  name: 'address-form',
  components: {
    ErrorMessage,
    Field,
    Form
  },
  emits: []
})
export default class AddressForm extends Vue.with(AddressFormProps) {
  google: any = null
  map: any = null
  marker: any = null

  context = setup(() => {
    const store = useStore()

    const addressModalState = reactive<AddressModalState>({
      addressNameList: [
        {
          id: AddressName.Home,
          name: 'Casa',
          icon: '/media/icons/duotone/Home/Home.svg',
          color: 'primary',
          isHovering: false
        },
        {
          id: AddressName.Work,
          name: 'Trabajo',
          icon: '/media/icons/duotone/Interface/Briefcase.svg',
          color: 'warning',
          isHovering: false
        },
        {
          id: AddressName.Other,
          name: 'Otra',
          icon: '/media/icons/duotone/Communication/Adress-book2.svg',
          color: 'dark',
          isHovering: false
        }
      ],
      mapDivRef: null,
      addressAutocompleteRef: null,
      markerIsDragging: false
    })

    const init = () => {
      if (!this.$props.address) {
        return
      }

      if (this.google) {
        return
      }

      const workspace = store.getters.currentWorkspace as AppWorkspace
      const loader = new Loader({
        apiKey: workspace?.AppGoogleCallcenterKey ?? Constans.APP_GOOGLEMAPS_KEY,
        version: 'weekly',
        libraries: ['places']
      })

      loader.load()
        .then((google) => {
          this.google = google

          if (!this.map) {
            const mapOptions = {
              center: { lat: 19.4326077, lng: -99.133208 },
              zoom: 8
            }

            this.map = new google.maps.Map(addressModalState.mapDivRef, mapOptions)
            this.marker?.setMap(null)
            this.marker = null
          }

          const options = {
            componentRestrictions: { country: 'mx' },
            fields: ['address_components', 'geometry', 'name', 'place_id', 'formatted_address'],
            strictBounds: false
          }

          const autocomplete = new google.maps.places.Autocomplete(addressModalState.addressAutocompleteRef, options)
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()
            if (!place.address_components) {
              return
            }

            fillAddressComponents(place, this.$props.address)
          })

          setTimeout(() => {
            addressModalState.addressAutocompleteRef?.setAttribute('autocomplete', '__away')
          }, 500)
        })
        .catch(() => {
          // ignored
        })
    }

    watch([
      () => this.$props.address?.AddressLat,
      () => this.$props.address?.AddressLng
    ], (newValues) => {
      if (!this.google || addressModalState.markerIsDragging) {
        return
      }

      if (this.map && newValues[0] && newValues[1]) {
        const position = {
          lat: newValues[0],
          lng: newValues[1]
        }

        if (this.marker) {
          this.marker.setPosition(position)
          this.map.setCenter(position)
          this.map.setZoom(16)
          return
        }

        this.marker = new this.google.maps.Marker({
          position,
          map: this.map,
          draggable: true
        })

        this.google.maps.event.addListener(this.marker, 'dragstart', () => {
          addressModalState.markerIsDragging = true
        })

        this.google.maps.event.addListener(this.marker, 'dragend', () => {
          const lat = this.marker?.getPosition()?.lat()
          const lng = this.marker?.getPosition()?.lng()

          if (this.$props.address) {
            this.$props.address.AddressLat = lat
            this.$props.address.AddressLng = lng
          }

          setTimeout(() => {
            addressModalState.markerIsDragging = false
          }, 200)
        })

        this.map.setCenter(position)
        this.map.setZoom(16)
      } else {
        this.map.setCenter({ lat: 19.4326077, lng: -99.133208 })
        this.map.setZoom(8)

        this.marker?.setMap(null)
        this.marker?.setVisible(false)
        this.marker = null
      }
    })

    return {
      ...toRefs(addressModalState),
      init
    }
  })

  init (): void {
    this.context?.init()
  }
}

function fillAddressComponents (place, address: Nullable<Address>): Nullable<Address> {
  if (!address) {
    return null
  }

  address.AddressNoInt = ''
  address.AddressLat = place.geometry?.location?.lat()
  address.AddressLng = place.geometry?.location?.lng()

  address.AddressGoogleId = place.place_id
  address.AddressGoogleDesc = place.formatted_address

  for (const component of place.address_components) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'route': {
        address.AddressStreet = component.long_name
        break
      }

      case 'street_number': {
        address.AddressNoExt = component.long_name
        break
      }

      case 'subpremise': {
        address.AddressNoInt = component.short_name
        break
      }

      case 'sublocality_level_1':
      case 'sublocality': {
        address.AddressNeighborhood = component.long_name
        break
      }

      case 'locality': {
        address.AddressMunicipality = component.long_name
        break
      }

      case 'administrative_area_level_1': {
        address.AddressState = component.long_name
        break
      }

      case 'postal_code': {
        address.AddressZipCode = component.short_name
        break
      }
    }
  }

  return address
}
