import authAxios from '@/services/core/AuthAxios'
import store from '@/store'
import { Services } from '@/store/enums/StoreEnums'
import { CartConfigInfo } from '@/store/modules/CartModule'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import ServiceHandler from '../core/ServiceHandler'
import AppWorkspace from '../workspaces/AppWorkspace'
import MenuData, { MenuDivisionGroupData } from './MenuData'
import MenuProductData from './MenuProductData'

class MenuService {
  getFullMenu (cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(
        Services.GET_MENU_FULL,
        workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            Menu: {
              GetOnlineProductsOnly: true
            },
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            },
            OrderDate: cartConfig.OrderDate,
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp
          }
        })
        .then(ServiceHandler.checkSuccess<MenuData>(MenuData))
        .catch(ServiceHandler.catchError())
    })
  }

  getFullMenuWithGroups (cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(
        Services.GET_MENU_FULL_GROUPS,
        workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            Menu: {
              GetOnlineProductsOnly: true
            },
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            },
            OrderDate: cartConfig.OrderDate,
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp
          }
        })
        .then(ServiceHandler.checkSuccess<MenuDivisionGroupData>(MenuDivisionGroupData))
        .catch(ServiceHandler.catchError())
    })
  }

  getProduct (id: number, cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(
        Services.GET_MENU_PRODUCT,
        workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            ProductId: id,
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            },
            OrderDate: cartConfig.OrderDate,
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp,
            GetOnlineProductsOnly: true
          }
        })
        .then(ServiceHandler.checkSuccess<MenuProductData>(MenuProductData))
        .catch(ServiceHandler.catchError())
    })
  }
}

export default new MenuService()
